export const LevelConst= [
    4000,
    9000,
    15000,
    22000,
    30000,
    39000,
    78000,
    156000,
    300000,
    600000
]
