import {Stripe} from '@stripe/stripe-js';
import { PaymentType } from '../context/payment/type/paymentType';
import { v4 } from 'uuid';

let stripePromise:  Promise<Stripe | null>;

const stripeToPaidHelper = (stripeHistory:any):PaymentType[] => {
    const result:PaymentType[] =[]
    if ( stripeHistory) {
        stripeHistory.map((item:any)=>{
            result.push({
                id: v4(),
                itemId:item.itemId,
                timestamp: item.timestamp,
                price: item.price,
                providerName: "stripe",
                providerPaymentId: item.paymentId,
                consumed: true
            })
        })
    }
    if ( process.env.NODE_ENV==="development" ) {
        console.log("WTFSTRIPETOPAID", result)
    }
    return result;
};

export default stripeToPaidHelper;
