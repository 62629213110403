import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import {
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent, ModalHeader,
    ModalOverlay
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { LoginForm } from "../form/loginForm";

export default function ShowLoginModal() {

    const { t } = useTranslation();
    const {
        showLoginModal,
        setShowLoginModal,
        user,
        //subscribeNewsletter,
        //setSubscribeNewsletter
    } = useContext(AuthenticationContext)
    
    return (
        <Modal
                isCentered
                size={"sm"}
                isOpen={showLoginModal}
                onClose={()=>setShowLoginModal(false)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader pt={2} textAlign={"center"}>{t("authentication.login") as string}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody
                        p={0}
                    >   
                        <LoginForm
                            allowAnonymousLogin={false}
                        />
                        {/*<Text
                            px={6}
                            pt={2}
                            fontSize={"0.8em"}
                            textAlign={"center"}
                        >
                            <Trans
                                components={{ strong: <strong/>}}
                                i18nKey="header.loginExplanation2"/>
                        </Text>
                        <div className="firebase-auth-container"/>
                        <Checkbox px={4} justifyContent={"center"} w={"full"} isChecked={subscribeNewsletter} onChange={()=>setSubscribeNewsletter(!subscribeNewsletter)}>{t("header.subscribeNewsletter")}</Checkbox>
                        */}
                    </ModalBody>
                </ModalContent>
            </Modal>
    )

}