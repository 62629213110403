import * as turf from '@turf/turf';
import { FaBullseye } from 'react-icons/fa6';
import { ZoneType } from '../types/zoneType';

export function calculateBoundingBox(lat:number, lng:number, distance:number) {
    const earthRadius = 6378.1; // Rayon de la Terre en km
    const rad = (x:number) => x * Math.PI / 180;

    const maxLat = lat + rad(distance / earthRadius) * (180 / Math.PI);
    const minLat = lat - rad(distance / earthRadius) * (180 / Math.PI);

    const maxLng = lng + rad(distance / earthRadius) * (180 / Math.PI) / Math.cos(rad(lat));
    const minLng = lng - rad(distance / earthRadius) * (180 / Math.PI) / Math.cos(rad(lat));

    return {
        minLat,
        maxLat,
        minLng,
        maxLng
    };
}

const earthRadius = 6378137;
const robustAcos = (value: number): number => {
    if (value > 1) {
        return 1;
    }
    if (value < -1) {
        return -1;
    }

    return value;
};

const toRad = (value: number) => (value * Math.PI) / 180;

export const getDistance = (
    from: {latitude:number, longitude:number},
    to: {latitude:number, longitude:number},
    accuracy: number = 1
) => {
    accuracy = typeof accuracy !== 'undefined' && !isNaN(accuracy) ? accuracy : 1;

    try {
      const fromLat = from.latitude;
      const fromLon = from.longitude;
      const toLat = to.latitude;
      const toLon = to.longitude;

      const distance =
          Math.acos(
              robustAcos(
                  Math.sin(toRad(toLat)) * Math.sin(toRad(fromLat)) +
                      Math.cos(toRad(toLat)) *
                          Math.cos(toRad(fromLat)) *
                          Math.cos(toRad(fromLon) - toRad(toLon))
              )
          ) * earthRadius;

      return Math.round(distance / accuracy) * accuracy;
    } catch (e) {
      return 0;
    }
    
};

export const insideGeojson = (geojson:any,location:any)=>{
    if( location) {
      try {
        const point = turf.point([location.lng, location.lat]);
        if ( geojson.geometry.coordinates[0].length >= 3) {
          const polygonFeature = turf.polygon(geojson.geometry.coordinates);
          return turf.booleanPointInPolygon(point, polygonFeature)
        }
      }catch(e){
        return false
      }
    }
    return false;
}

export const generateRandomPoints = (coords:any) => {
    if (!coords || !coords.length  ) return;

    const polygon = coords.map((coord:any) => [coord.lng, coord.lat]);
    const polygonFeature = turf.polygon([[...polygon, polygon[0]]]);
    const bbox = turf.bbox(polygonFeature);
    const pointsInside:any[] = [];
    const minDistance = 0.01; // Distance minimale en degrés (~1 km, ajustez selon vos besoins)
    let limit=0
    while (pointsInside.length < 5 && limit<25) {
      const points = turf.randomPoint(5 - pointsInside.length, { bbox }).features;

      points.forEach(point => {
        if (turf.booleanPointInPolygon(point, polygonFeature)) {
          const isFarEnough = pointsInside.every(existingPoint => {
            return turf.distance(point, existingPoint, { units: 'kilometers' }) >= minDistance;
          });

          if (isFarEnough) {
            pointsInside.push(point);
          }
        }
      });
      limit++
    }
    return pointsInside
}

export const getAreaSize = (coords:any) => {
    if (!coords || !coords.length  ) return;

    const polygon = coords.map((coord:any) => [coord.lng, coord.lat]);
    if ( ! polygon || polygon.length < 3 ) {
        return 0
    }
    const polygonArea = turf.polygon([[...polygon, polygon[0]]]);
    // Calculer la superficie en mètres carrés
    const area = turf.area(polygonArea);

    return area
}

export const calculateBearing=(lat1:number, lon1:number, lat2:number, lon2:number) => {
    const toRadians = (degrees:number) => degrees * Math.PI / 180;
    const toDegrees = (radians:number) => radians * 180 / Math.PI;

    const φ1 = toRadians(lat1);
    const φ2 = toRadians(lat2);
    const Δλ = toRadians(lon2 - lon1);

    const y = Math.sin(Δλ) * Math.cos(φ2);
    const x = Math.cos(φ1) * Math.sin(φ2) -
              Math.sin(φ1) * Math.cos(φ2) * Math.cos(Δλ);

    const bearing = toDegrees(Math.atan2(y, x));
    return (bearing + 360) % 360; // Normalisation de l'angle en [0, 360]
}

export const getMinimalRotation=(currentBearing:number, newBearing:number) =>{
  const delta = (newBearing - currentBearing) % 360;
  const minimalRotation = delta > 180 ? delta - 360 : delta < -180 ? delta + 360 : delta;
  return currentBearing + minimalRotation;
}

export const  smoothBearing=(oldBearing:number, newBearing:number, factor = 0.1)=> {
    if (oldBearing === null) {
      oldBearing = newBearing;
    } else {
      oldBearing = factor * newBearing + (1 - factor) * oldBearing;
    }
    return oldBearing;
}

export const doesZoneOverlapWithExisting = (zoneCoords: { lat: number, lng: number }[], existingZones: ZoneType[]) => {
    // Créer un polygone à partir des coordonnées de la zone à vérifier
    if ( !zoneCoords || zoneCoords.length < 4 ) {
        return false
    } 
    try {
      const zonePolygon = turf.polygon([zoneCoords.map(coord => [coord.lng, coord.lat])]);

      // Vérifier chaque zone existante
      return existingZones.some(existingZone => {
          if ( existingZone.coords.length <4 ) {
              return false
          }
          // Créer un polygone pour la zone existante
          const coords = validatePolygonIfNeeded(existingZone.coords)
          if (!coords) {
              return false
          }
          const existingPolygon = turf.polygon([coords.map((coord:any) => [coord.lng, coord.lat])]);

          // Vérifier le chevauchement ou inclusion
          return turf.booleanOverlap(existingPolygon, zonePolygon) || 
                turf.booleanContains(existingPolygon, zonePolygon) || 
                turf.booleanContains(zonePolygon, existingPolygon);
      });
  }catch(e){
      return false
  }
};

export const validatePolygonIfNeeded = (coords:any) => {
    const firstCoord = coords[0];
    const lastCoord = coords[coords.length - 1];

    // Vérifier si le polygone est fermé (la première coordonnée doit être la même que la dernière)
    if (firstCoord.lat !== lastCoord.lat || firstCoord.lng !== lastCoord.lng) {
        // Ajouter la première coordonnée à la fin pour fermer le polygone
        coords.push({ lat: firstCoord.lat, lng: firstCoord.lng });
    }

    if (coords.length < 3) {
      return null
  }

    return coords;
};