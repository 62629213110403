import { BadgeType } from "../context/global/type/badgeType";

export const AllBadges: BadgeType[] = [
    // Feed badges
    {
      id: 'feed',
      url: '/images/badgeFeed.jpeg',
      name: 'Nourrisseur Occasionnel',
      label: "1x",
      bg: "#45270d",
      gift: "100food",
      color:"white",
      criteria: (actions:any) => {
        const feedActions = actions.filter((action:any) => action.type === 'feed');
        if (feedActions.length < 1) return false;
  
        return true;
      }
    },
    {
        id: 'feed-2-days',
        url: '/images/badgeFeed.jpeg',
        name: 'Nourrisseur Régulier',
        label: '1/j-2j',
        bg: "#d5d4d9",
        color: "black",
        criteria: (actions) => {
          const feedActions = actions.filter((action) => action.type === 'feed');
          if (feedActions.length < 2) return false;
    
          const lastTwooDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-2).map((action:any) => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastTwooDays.length; i++) {
            const prevDay = new Date(lastTwooDays[i - 1]);
            const currDay = new Date(lastTwooDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'feed-3-days',
        url: '/images/badgeFeed.jpeg',
        name: 'Nourrisseur Dévoué',
        label: '1/j-3j',
        bg: "#ffbf00",
        color: "black",
        criteria: (actions) => {
          const feedActions = actions.filter(action => action.type === 'feed');
          if (feedActions.length < 3) return false;
    
          const lastThreeDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-3).map(action => action.date);
    
          if ( process.env.NODE_ENV==="development" ) {    
            console.log("badge feed 3 days",lastThreeDays)
          }
          
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastThreeDays.length; i++) {
            const prevDay = new Date(lastThreeDays[i - 1]);
            const currDay = new Date(lastThreeDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'feed-5-days',
        url: '/images/badgeFeed.jpeg',
        name: 'Nourrisseur Passionné',
        label: '1/j-5j',
        criteria: (actions) => {
          const feedActions = actions.filter(action => action.type === 'feed');
          if (feedActions.length < 5) return false;
    
          const lastFiveDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-5).map(action => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastFiveDays.length; i++) {
            const prevDay = new Date(lastFiveDays[i - 1]);
            const currDay = new Date(lastFiveDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      // Connection badges
    {
      id: 'connection',
      url: '/images/badgeRegular.jpeg',
      name: 'Joueur Occasionnel',
      label: "1j",
      bg: "#45270d",
      color:"white",
      criteria: (actions:any) => {
        return false
        const check = actions.filter((action:any) => action.type === 'connection');
        if (check.length < 1) return false;
  
        return true;
      }
    },
    {
        id: 'connection-2-days',
        url: '/images/badgeRegular.jpeg',
        name: 'Joueur Régulier',
        label: '2j',
        bg: "#d5d4d9",
        color: "black",
        criteria: (actions) => {
          const check = actions.filter((action) => action.type === 'connection');
          if (check.length < 2) return false;
    
          const lastTwooDays = check.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-2).map((action:any) => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastTwooDays.length; i++) {
            const prevDay = new Date(lastTwooDays[i - 1]);
            const currDay = new Date(lastTwooDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'connection-3-days',
        url: '/images/badgeRegular.jpeg',
        name: 'Joueur Dévoué',
        label: '3j',
        bg: "#ffbf00",
        color: "black",
        gift: "100food",
        criteria: (actions) => {
          const check = actions.filter(action => action.type === 'connection');
          if (check.length < 3) return false;
    
          const lastThreeDays = check.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-3).map(action => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastThreeDays.length; i++) {
            const prevDay = new Date(lastThreeDays[i - 1]);
            const currDay = new Date(lastThreeDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'connection-5-days',
        url: '/images/badgeRegular.jpeg',
        name: 'Joueur Passionné',
        label: '5j',
        criteria: (actions) => {
          const check = actions.filter(action => action.type === 'connection');
          if (check.length < 5) return false;
    
          const lastFiveDays = check.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-5).map(action => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastFiveDays.length; i++) {
            const prevDay = new Date(lastFiveDays[i - 1]);
            const currDay = new Date(lastFiveDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      // Kilometer badges
      {
        id: 'distance-1km',
        url: '/images/badgeKilometers.jpeg',
        name: 'Marathonien débutant',
        label: '1km',
        bg: "#45270d",
        color:"white",
        criteria: (actions) => {
            const totalDistance = actions
                .filter(action => action.type === 'kilometer')
                .reduce((sum, action) => sum + (action.count || 0), 0);
    
            return totalDistance >= 1000; // 10,000 mètres = 10 kilomètres
        }
    },
      {
        id: 'distance-5km',
        url: '/images/badgeKilometers.jpeg',
        name: 'Marathonien en Herbe',
        label: '5km',
        bg: "#d5d4d9",
        color: "black",
        criteria: (actions) => {
            const totalDistance = actions
                .filter(action => action.type === 'kilometer')
                .reduce((sum, action) => sum + (action.count || 0), 0);
    
            return totalDistance >= 5000; // 10,000 mètres = 10 kilomètres
        }
    },
    {
        id: 'distance-10km',
        url: '/images/badgeKilometers.jpeg',
        name: 'Marathonien assidu',
        label: '10km',
        bg: "#ffbf00",
        color: "black",
        gift: "creature",
        criteria: (actions) => {
            const totalDistance = actions
                .filter(action => action.type === 'kilometer')
                .reduce((sum, action) => sum + (action.count || 0), 0);
    
            return totalDistance >= 10000; // 10,000 mètres = 10 kilomètres
        }
    },
    {
        id: 'distance-15km',
        url: '/images/badgeKilometers.jpeg',
        name: 'Marathonien confirmé',
        label: '15km',
        criteria: (actions) => {
            const totalDistance = actions
                .filter(action => action.type === 'kilometer')
                .reduce((sum, action) => sum + (action.count || 0), 0);
    
            return totalDistance >= 15000; // 10,000 mètres = 10 kilomètres
        }
    },
    {
        id: 'distance-30km',
        url: '/images/badgeKilometers.jpeg',
        name: 'Marathonien Expert',
        label: '30km',
        bg: "#d69eff",
        color: "black",
        criteria: (actions) => {
            const totalDistance = actions
                .filter(action => action.type === 'kilometer')
                .reduce((sum, action) => sum + (action.count || 0), 0);
    
            return totalDistance >= 30000; // 10,000 mètres = 10 kilomètres
        }
    },
    {
        id: 'distance-60km',
        url: '/images/badgeKilometers.jpeg',
        name: 'Marathonien Légendaire',
        label: '60km',
        bg: "#e5056b",
        color: "white",
        criteria: (actions) => {
            const totalDistance = actions
                .filter(action => action.type === 'kilometer')
                .reduce((sum, action) => sum + (action.count || 0), 0);
    
            return totalDistance >= 60000; // 10,000 mètres = 10 kilomètres
        }
    } ,
    // Goobles badges
    {
      id: 'gooblies-2',
      url: '/images/badgeMonster.jpeg',
      name: 'Collectionneur amateur',
      label: '2',
      bg: "#45270d",
      color:"white",
      gift: "100food",
      criteria: (actions) => {
          const total = actions
              .filter(action => action.type === 'newCreature')
              .reduce((sum, action) => sum + (action.count || 0), 0);
  
          return total>= 2
      }
  },
  {
      id: 'gooblies-5',
      url: '/images/badgeMonster.jpeg',
      name: 'Collectionneur confirmé',
      label: '5',
      bg: "#d5d4d9",
      color: "black",
      criteria: (actions) => {
          const total = actions
              .filter(action => action.type === 'newCreature')
              .reduce((sum, action) => sum + (action.count || 0), 0);
  
          return total >= 5;
      }
  },
  {
      id: 'gooblies-10',
      url: '/images/badgeMonster.jpeg',
      name: 'Collectionneur expert',
      label: '10',
      bg: "#ffbf00",
        color: "black",
      criteria: (actions) => {
          const total = actions
              .filter(action => action.type === 'newCreature')
              .reduce((sum, action) => sum + (action.count || 0), 0);
  
          return total >= 10; 
      }
  },
  {
      id: 'gooblies-20',
      url: '/images/badgeMonster.jpeg',
      name: 'Collectionneur légendaire',
      label: '20',
      criteria: (actions) => {
          const total = actions
              .filter(action => action.type === 'newCreature')
              .reduce((sum, action) => sum + (action.count || 0), 0);
  
          return total >= 20;
      }
  },
  {
      id: 'gooblies-50',
      url: '/images/badgeMonster.jpeg',
      name: 'Collectionneur ultime',
      label: '50',
      bg: "#d69eff",
      color: "black",
      criteria: (actions) => {
          const total = actions
              .filter(action => action.type === 'newCreature')
              .reduce((sum, action) => sum + (action.count || 0), 0);

          return total >= 50;
      }
  },
  // Friends badges
  {
    id: 'friend-1',
    url: '/images/badgeFriends.jpeg',
    name: 'Amitié naissante',
    label: '1',
    bg: "#45270d",
    color:"white",
    criteria: (actions) => {
        const totalDistance = actions
            .filter(action => action.type === 'friends')
            .reduce((sum, action) => sum + (action.count || 0), 0);

        return totalDistance >= 1; // 10,000 mètres = 10 kilomètres
    }
  },
    {
      id: 'friend-2',
      url: '/images/badgeFriends.jpeg',
      name: 'Amitié florissante',
      label: '2',
      bg: "#d5d4d9",
      color: "black",
      criteria: (actions) => {
          const totalDistance = actions
              .filter(action => action.type === 'friends')
              .reduce((sum, action) => sum + (action.count || 0), 0);

          return totalDistance >= 2; // 10,000 mètres = 10 kilomètres
      }
  },
  {
      id: 'friend-3',
      url: '/images/badgeFriends.jpeg',
      name: 'Amitié solide',
      label: '3',
      bg: "#ffbf00",
      color: "black",
      gift: "creature",
      criteria: (actions) => {
          const totalDistance = actions
              .filter(action => action.type === 'friends')
              .reduce((sum, action) => sum + (action.count || 0), 0);

          return totalDistance >= 3; // 10,000 mètres = 10 kilomètres
      }
  },
  {
    id: 'friend-10',
    url: '/images/badgeFriends.jpeg',
    name: 'Amitié indestructible',
    label: '10',
    criteria: (actions) => {
        const totalDistance = actions
            .filter(action => action.type === 'friends')
            .reduce((sum, action) => sum + (action.count || 0), 0);

        return totalDistance >= 10; // 10,000 mètres = 10 kilomètres
    }
  },
  {
    id: 'friend-20',
    url: '/images/badgeFriends.jpeg',
    name: 'Amitié éternelle',
    label: '20',
    bg: "#d69eff",
    color: "black",
    criteria: (actions) => {
        const totalDistance = actions
            .filter(action => action.type === 'friends')
            .reduce((sum, action) => sum + (action.count || 0), 0);

        return totalDistance >= 20; // 10,000 mètres = 10 kilomètres
    }
  },
  // Laugh badges
  {
    id: 'laugh',
    url: '/images/badgeLove.jpeg',
    name: 'Soigneur Occasionnel',
    label: "1x",
    bg: "#45270d",
    color:"white",
    criteria: (actions:any) => {
      const feedActions = actions.filter((action:any) => action.type === 'laugh');
      if (feedActions.length < 1) return false;

      return true;
    }
  },
  {
      id: 'laugh-2-days',
      url: '/images/badgeLove.jpeg',
      name: 'Soigneur Régulier',
      label: '1/j-2j',
      bg: "#d5d4d9",
      color: "black",
      criteria: (actions) => {
        const feedActions = actions.filter((action) => action.type === 'laugh');
        if (feedActions.length < 2) return false;
  
        const lastTwooDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-2).map((action:any) => action.date);
  
        // Vérifie que les trois dernières dates sont consécutives
        for (let i = 1; i < lastTwooDays.length; i++) {
          const prevDay = new Date(lastTwooDays[i - 1]);
          const currDay = new Date(lastTwooDays[i]);
  
          prevDay.setDate(prevDay.getDate() + 1);
  
          if (prevDay.toDateString() !== currDay.toDateString()) {
            return false;
          }
        }
  
        return true;
      }
    },
    {
      id: 'laugh-3-days',
      url: '/images/badgeLove.jpeg',
      name: 'Soigneur Dévoué',
      label: '1/j-3j',
      bg: "#ffbf00",
      color: "black",
      criteria: (actions) => {
        const feedActions = actions.filter(action => action.type === 'laugh');
        if (feedActions.length < 3) return false;
  
        const lastThreeDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-3).map(action => action.date);
  
        // Vérifie que les trois dernières dates sont consécutives
        for (let i = 1; i < lastThreeDays.length; i++) {
          const prevDay = new Date(lastThreeDays[i - 1]);
          const currDay = new Date(lastThreeDays[i]);
  
          prevDay.setDate(prevDay.getDate() + 1);
  
          if (prevDay.toDateString() !== currDay.toDateString()) {
            return false;
          }
        }
  
        return true;
      }
    },
    {
      id: 'laugh-5-days',
      url: '/images/badgeLove.jpeg',
      name: 'Soigneur Passionné',
      label: '1/j-5j',
      gift: "creature",
      criteria: (actions) => {
        const feedActions = actions.filter(action => action.type === 'laugh');
        if (feedActions.length < 5) return false;
  
        const lastFiveDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-5).map(action => action.date);
  
        // Vérifie que les trois dernières dates sont consécutives
        for (let i = 1; i < lastFiveDays.length; i++) {
          const prevDay = new Date(lastFiveDays[i - 1]);
          const currDay = new Date(lastFiveDays[i]);
  
          prevDay.setDate(prevDay.getDate() + 1);
  
          if (prevDay.toDateString() !== currDay.toDateString()) {
            return false;
          }
        }
  
        return true;
      }
    },

    // Eggs badges
    {
      id: 'eggs',
      url: '/images/badgeEggs.jpeg',
      name: 'Couveur Novice',
      label: "1x",
      bg: "#45270d",
      color:"white",
      criteria: (actions:any) => {
        const feedActions = actions.filter((action:any) => action.type === 'careEgg');
        if (feedActions.length < 1) return false;
  
        return true;
      }
    },
    {
        id: 'eggs-2-days',
        url: '/images/badgeEggs.jpeg',
        name: 'Couveur Diligent',
        label: '1/j-2j',
        bg: "#d5d4d9",
        color: "black",
        gift: "100food",
        criteria: (actions) => {
          const feedActions = actions.filter((action) => action.type === 'careEgg');
          if (feedActions.length < 2) return false;
    
          const lastTwooDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-2).map((action:any) => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastTwooDays.length; i++) {
            const prevDay = new Date(lastTwooDays[i - 1]);
            const currDay = new Date(lastTwooDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'eggs-3-days',
        url: '/images/badgeEggs.jpeg',
        name: 'Protecteur des Œufs',
        label: '1/j-3j',
        bg: "#ffbf00",
        color: "black",
        criteria: (actions) => {
          const feedActions = actions.filter(action => action.type === 'careEgg');
          if (feedActions.length < 3) return false;
    
          const lastThreeDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-3).map(action => action.date);
    
          if ( process.env.NODE_ENV==="development" ) {    
            console.log("badge feed 3 days",lastThreeDays)
          }
          
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastThreeDays.length; i++) {
            const prevDay = new Date(lastThreeDays[i - 1]);
            const currDay = new Date(lastThreeDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'eggs-5-days',
        url: '/images/badgeEggs.jpeg',
        name: 'Maître Couvreur',
        label: '1/j-5j',
        criteria: (actions) => {
          const feedActions = actions.filter(action => action.type === 'careEgg');
          if (feedActions.length < 5) return false;
    
          const lastFiveDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-5).map(action => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastFiveDays.length; i++) {
            const prevDay = new Date(lastFiveDays[i - 1]);
            const currDay = new Date(lastFiveDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
      {
        id: 'eggs-7-days',
        url: '/images/badgeEggs.jpeg',
        name: 'Légendaire Gardien des Œufs',
        label: '1/j-7j',
        criteria: (actions) => {
          const feedActions = actions.filter(action => action.type === 'careEgg');
          if (feedActions.length < 7) return false;
    
          const lastFiveDays = feedActions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()).slice(-7).map(action => action.date);
    
          // Vérifie que les trois dernières dates sont consécutives
          for (let i = 1; i < lastFiveDays.length; i++) {
            const prevDay = new Date(lastFiveDays[i - 1]);
            const currDay = new Date(lastFiveDays[i]);
    
            prevDay.setDate(prevDay.getDate() + 1);
    
            if (prevDay.toDateString() !== currDay.toDateString()) {
              return false;
            }
          }
    
          return true;
        }
      },
];