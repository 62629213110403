import { useContext } from "react";
import { Button, Text, Flex, Input } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { PaymentContext } from "@/app/context/payment/paymentContext";
import { buildApiStripeCheckouUrl } from "@/app/helpers/buildUrlHelper";
import { ContentPriceType } from "../type/contentPriceType";

type Props = {
    categoryId?:number, 
    content: ContentPriceType
}

export const ButtonPaymentStripeCheckout = ({categoryId, content }:Props) => {
    const router = useRouter();
    const { language } = router.query;
    const {t} = useTranslation();

    const {
        user
    } = useContext(AuthenticationContext)

    const {
        setErrorPayment
    } = useContext(PaymentContext)

    const handleCheckout=async()=>{

        const options = {
            method: "POST",
            headers: {
                "Content-type": "application/json",
                'Authorization': "Bearer " + await user?.getIdToken()
            },
            body: JSON.stringify({
                itemId:content.id,
                categoryId:categoryId,
                priceId:content.providerId.stripe
            })
        }
        const checkoutUrl = await fetch(process.env.NEXT_PUBLIC_API_URL_V2 + buildApiStripeCheckouUrl(language as string), options).then((res) =>
            res.json()
        )

        if ( checkoutUrl && checkoutUrl.url){
            await router.push(checkoutUrl.url)
        }else{
            setErrorPayment(true)
        }
    }

    return(
        <>
            <Flex
                flexDirection={"column"}
            >
            <Button
                type={"submit"}
                background="#f16529!important" 
                color="white!important" 
                m={0}
                ml={3}
                onClick={handleCheckout}
            >
                {t("payment.doPay")} : {content.price} €
            </Button>            
            </Flex>
        </>
    )
}
