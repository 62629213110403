import { Auth, getAuth } from "firebase/auth";
import "firebase/compat/analytics";
import firebase from "firebase/compat/app";
import "firebaseui/dist/firebaseui.css";
import { createContext, useEffect, useState } from "react";
import { Firestore, getFirestore } from "firebase/firestore";
import "@firebase/messaging";
import { FirebaseType } from "../../types/firebaseType";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
import { getApps, initializeApp } from 'firebase/app';
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';


import App = firebase.app.App;

export const FirebaseContext = createContext({} as FirebaseType)

type Props = {
    firebaseConfig: {
        apiKey: string | undefined
        authDomain: string | undefined
        projectId: string | undefined
        storageBucket: string | undefined
        messagingSenderId: string | undefined
        appId: string | undefined
        measurementId: string    | undefined      
    },
    children: React.ReactNode 
}


export const FirebaseProvider= ({
    firebaseConfig,
    children
}:Props) => {

    const [isFirebaseLoading, setIsFirebaseLoading] = useState(true)
    const [appState, setAppState] = useState<firebase.app.App|null>(null)
    const [authState, setAuthState] = useState<Auth|null>(null)
    const [dbState, setDbState] = useState<Firestore|null>(null)
    const [analyticState, setAnalyticState] = useState<Analytics|null>(null)
    

    const logAnalyticsEvent = (eventName:string, eventParams={}) => {
        try {
            logEvent(analyticState as Analytics, eventName, eventParams)
        }catch(e){
            console.error("Error logging event",e)
        }
    }

    useEffect(()=>{
        if ( process.env.NODE_ENV==="development" ) {
            console.log("firebaseContext initialize")
        }
        const appFirebase = getApps().length === 0 ? initializeApp({...firebaseConfig,
            databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASEURL as string}) : getApps()[0];
        setAppState(appFirebase as App)

        
        const appCheck = initializeAppCheck(appFirebase, {
            provider: new ReCaptchaV3Provider(process.env.NEXT_PUBLIC_CAPTCHA_SITE_KEY as string),
            isTokenAutoRefreshEnabled: true, // Option pour rafraîchir automatiquement les jetons App Check
        });

        setDbState(getFirestore(appFirebase))
        const authFirebase = getAuth(appFirebase);
        setAuthState(authFirebase)
        const analytic = getAnalytics(appFirebase);
        setAnalyticState(analytic)
        setIsFirebaseLoading(false)

    },[])

   
    return (
        <FirebaseContext.Provider value={{
            isFirebaseLoading,
            appState,
            authState,
            logAnalyticsEvent,
            dbState
        }}>
            {children}
        </FirebaseContext.Provider>
    )
}
