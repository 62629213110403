import { useContext } from "react";
import { AuthenticationContext } from "../context/authentication/authenticationContext";
import { GlobalDataContext } from "../context/global/globalDataContext";

const useFriendRequests = () => {

    const {
        language
    } = useContext(GlobalDataContext)
    const {
        user
    } = useContext( AuthenticationContext)

    const searchUser = async(email:string) => {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    'Authorization': "Bearer " + await user?.getIdToken()
                },
                body: JSON.stringify({ q: email})
            }
            const response = await fetch(process.env.NEXT_PUBLIC_API_URL_V2 + "search_user", options)
            const check = await response.json()
            
            return check
        } catch (error) {
            return false
        }
    };

    const retrieveUsers = async(userIds:any[]) => {
        try {
            const options = {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    'Authorization': "Bearer " + await user?.getIdToken()
                },
                body: JSON.stringify({ users: userIds})
            }
            const response = await fetch(process.env.NEXT_PUBLIC_API_URL_V2 + "retrieve_users", options)
            const check = await response.json()
            
            return check
        } catch (error) {
            return false
        }
    };

    const acceptFriendRequest = async(userId:string) => {
        try {
            
        } catch (error) {
            return false
        }
    };

    const declineFriendRequest = async(userId:string) => {
        try {
            
        } catch (error) {
            return false
        }
    };

    return { searchUser, retrieveUsers}
}

export default useFriendRequests