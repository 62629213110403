import { MyCreaturesType } from "@/app/types/myCreaturesType";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";
import { MyItemsType } from "@/app/types/myItemsType";

export const useMyItems = () => {
    const initialState:MyItemsType[] = []
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, isSigning, isNewUser, logOut, cloudStorage, wasUserNotConnected} = useContext(AuthenticationContext)

    type Action =
        | { type: 'ADD'; payload: MyItemsType[] }
        | { type: 'UPDATE'; payload: MyItemsType[] }
        | { type: 'ADD_FROM_CLOUD'; payload: MyItemsType[] }
        | { type: 'UPDATE_FROM_CLOUD'; payload: MyItemsType[] }
        | { type: 'DELETE'; payload: MyItemsType[] }
        | { type: 'CARE'; payload: {id: string} }
        | { type: 'EMPTY' }


    const reducer= (state:any, action:Action) => {
        switch (action.type) {
            case 'ADD':
                return [
                    ...state, 
                    ...action.payload.map((item: MyItemsType) => ({
                        ...item,
                        isSynchronized: false,
                    }))
                ]; 
            case 'UPDATE':
                return state.map((item: any) => {
                        const updatedItem = action.payload.find((itemAction:MyItemsType) => itemAction.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: false };
                        }
                        return item;
                    });
            case 'ADD_FROM_CLOUD':
                return [
                    ...state, 
                    ...action.payload.map((item: MyItemsType) => ({
                        ...item,
                        isSynchronized: true,
                    }))
                ]; 
            case 'UPDATE_FROM_CLOUD':
                return state.map((item: MyItemsType) => {
                        const updatedItem = action.payload.find((itemAction:MyItemsType) => itemAction.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: true };
                        }
                        return item;
                    });
            case 'CARE':
                return state.map((item: MyItemsType) => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            levelCare: item.levelCare+1,
                            lastCare: Date.now(), 
                            isSynchronized: false 
                        };
                    }
                    return item;
                    });
            case 'DELETE':
                return state.filter((item: MyItemsType) => !action.payload.some((creature:MyItemsType) => creature.id === item.id)) ;

            case 'EMPTY':
                return [];

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);


    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const add=(datas: MyItemsType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'ADD', 
            payload: datas})
    }

    const update=(datas: MyItemsType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'UPDATE', 
            payload: datas})
    }

    const remove=(datas: MyItemsType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'DELETE', 
            payload: datas})
    }

    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    const care=(creatureId: string)=>{
        if ( !creatureId ) { return }
        dispatch({
            type: 'CARE', 
            payload: {id: creatureId}})
    }

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
            const unsubscribe = cloudStorage?.subscribeToMyItems(
                (eggs, eggsToRemove)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("unsubscribeToMyEggs", eggs)
                    }
                    if ( eggs && eggs.length>0 ) {
                        const itemsToAdd = eggs?.filter(
                            (item:MyItemsType) => !stateRef.current.some((stateItem: MyItemsType) => stateItem.id === item.id)
                        ) || [];
                        if ( itemsToAdd && itemsToAdd.length > 0 ){
                            dispatch({
                                type: 'ADD_FROM_CLOUD', 
                                payload: itemsToAdd})
                        }

                        const updatedItemsState = eggs
                        .filter(
                            (item:MyItemsType) => stateRef.current.some((stateItem: MyItemsType) => stateItem.id === item.id)
                        ) || []
                
                        if ( updatedItemsState && updatedItemsState.length > 0 ){
                            dispatch({
                                type: 'UPDATE_FROM_CLOUD', 
                                payload: updatedItemsState})
                        }
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("add and update for eggs", itemsToAdd, updatedItemsState)
                        }
                        
                    }

                    if ( eggsToRemove && eggsToRemove.length > 0 ){
                        dispatch({
                            type: 'DELETE', 
                            payload: eggsToRemove})
                    }
                },
                setIsLoading
            )

            return () => {
                if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])


    useEffect(()=>{
        if ( cloudStorage && user && state && state.length > 0 ){
            setIsLoading(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setEggsToCloud preSave")
            }
            const unsynchronizedItems = state.filter((item:MyItemsType)=>!item.isSynchronized)
            if ( unsynchronizedItems && unsynchronizedItems.length > 0 ){
                cloudStorage?.setItemsToCloud(unsynchronizedItems).then((result)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("setEggsToCloud", result)
                    }
                })
            }
        }
    }, [cloudStorage, user, state])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    useEffect(()=>{
        if (isSigning) { 
            emptyState()
            setIsLoading(true)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wasUserNotConnected", state)
            }
        }
    },[isSigning])


    useEffect(()=>{
        if ( logOut ){
            emptyState()
        }
    },[logOut])

    return {
        state,
        isLoading,
        add,
        care,
        remove
    }
}