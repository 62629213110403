import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";
import { TradeRequestType } from "@/app/types/tradeRequestType";

export const useTradeRequests = () => {
    const initialState:TradeRequestType[] = []
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, logOut, cloudStorage} = useContext(AuthenticationContext)

    type Action =
        | { type: 'ADD'; payload: TradeRequestType[] }
        | { type: 'UPDATE'; payload: TradeRequestType[] }
        | { type: 'ADD_FROM_CLOUD'; payload: TradeRequestType[] }
        | { type: 'UPDATE_FROM_CLOUD'; payload: TradeRequestType[] }
        | { type: 'EMPTY' }


    const reducer= (state:any, action:Action) => {
        switch (action.type) {
            case 'ADD':
                return [
                    ...state, 
                    ...action.payload.map((item: TradeRequestType) => ({
                        ...item,
                        isSynchronized: false,
                    }))
                ]; 
            case 'UPDATE':
                return state.map((item: any) => {
                        const updatedItem = action.payload.find((itemAction:TradeRequestType) => itemAction.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: false };
                        }
                        return item;
                    });
            case 'ADD_FROM_CLOUD':
                return [
                    ...state, 
                    ...action.payload.map((item: TradeRequestType) => ({
                        ...item,
                        isSynchronized: true,
                    }))
                ]; 
            case 'UPDATE_FROM_CLOUD':
                return state.map((item: TradeRequestType) => {
                        const updatedItem = action.payload.find((itemAction:TradeRequestType) => itemAction.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: true };
                        }
                        return item;
                    });
        
            case 'EMPTY':
                return [];

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
    }, [state]);

    const add=(datas: TradeRequestType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'ADD', 
            payload: datas})
    }

    const update=(datas: TradeRequestType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'UPDATE', 
            payload: datas})
    }

    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
            const unsubscribe = cloudStorage?.subscribeToTradeRequests(
                (trade)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("subscribeToTradeRequests", trade)
                    }
                    if ( trade && trade.length>0 ) {
                        const itemsToAdd = trade?.filter(
                            (item:TradeRequestType) => !stateRef.current.some((stateItem: TradeRequestType) => stateItem.id === item.id)
                        ) || [];
                        if ( itemsToAdd && itemsToAdd.length > 0 ){
                            dispatch({
                                type: 'ADD_FROM_CLOUD', 
                                payload: itemsToAdd})
                        }

                        const updatedItemsState = trade
                        .filter(
                            (item:TradeRequestType) => stateRef.current.some((stateItem: TradeRequestType) => stateItem.id === item.id)
                        ) || []
                
                        if ( updatedItemsState && updatedItemsState.length > 0 ){
                            dispatch({
                                type: 'UPDATE_FROM_CLOUD', 
                                payload: updatedItemsState})
                        }
                    }
                },
                setIsLoading
            )

            return () => {
                if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    useEffect(()=>{
        if ( cloudStorage && user && state && state.length > 0 ){
            setIsLoading(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setTradeRequestsToCloud preSave")
            }
            const unsynchronizedItems = state.filter((item:TradeRequestType)=>!item.isSynchronized)
            if ( unsynchronizedItems && unsynchronizedItems.length > 0 ){
                cloudStorage?.setTradeRequestsToCloud(unsynchronizedItems).then((result)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("setTradeRequestsToCloud", result)
                    }
                })
            }
        }
    }, [cloudStorage, user, state])

    useEffect(()=>{
        if ( logOut ){
            emptyState()
        }
    },[logOut])

    return {
        state,
        isLoading,
        add,
    }
}