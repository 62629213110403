import React, { Dispatch, MouseEventHandler, useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "next-i18next";
import { Flex, FormControl, FormHelperText, Input, FormLabel, Button, Text, Box, Divider, useToast, Alert, AlertIcon, CloseButton, Heading, Checkbox, InputGroup, InputRightElement } from "@chakra-ui/react";
import {FcGoogle} from "react-icons/fc";
import { FaSquareFacebook } from "react-icons/fa6";
import { ImTwitter } from "react-icons/im";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
//import { PaymentContext, PaymentProvider } from "@/app/context/authentication/layout/form/paymentContext";
import { PiEyeLight, PiEyeSlash } from "react-icons/pi";
import { MdPerson } from "react-icons/md";
import { NotificationContext } from "@/app/context/notification/notificationContext";

type Props = {
    allowAnonymousLogin?: any
}

export const LoginForm = ({allowAnonymousLogin=false}:Props) => {
    const { t } = useTranslation();
    
    const {
        addError,
    } = useContext(NotificationContext)

    const [inAppBrowsing, setInAppBrowsing] = useState(false)
    const [forgotPassword, setForgotPassword] = useState(false)
    const [stateLogin, setStateLogin] = useState("login")

    const [showPasswordLogin, setShowPasswordLogin] = useState(false);
    const [showPasswordCreate, setShowPasswordCreate] = useState(false);
    const [showConfirmCreate, setShowConfirmCreate] = useState(false);

    const [showPasswordNotStrongEnough, setShowPasswordNotStrongEnough] = useState(false)

    const [formLogin, setFormLogin] = useState({
        email: "",
        password: "",
    });

    const [formCreate, setFormCreate] = useState({
        email: "",
        password: "",
        confirm: ""
    });

    const [formReset, setFormReset] = useState({
        email: ""
    });

    const {
        showLoginModal,
        anonymousLogin,
        signInWithGoogle,
        signInWithFacebook,
        signInWithTwitter,
        signInWithCredentials,
        //subscribeNewsletter,
        //setSubscribeNewsletter,
        createUserWithCredentials,
        resetPasswordByEmail
    } = useContext(AuthenticationContext)
    /*
    const {
        showPaidContentModal
    } = useContext(PaymentContext)
*/
    useEffect(()=>{
        if (navigator && navigator.userAgent.match(/FBAN|FBAV/i)) {
           setInAppBrowsing(true)
        }
    },[])

    useEffect(()=>{
        if ( showLoginModal ) {
            setStateLogin("login")
            setShowConfirmCreate(false)
            setShowPasswordCreate(false)
            setShowPasswordLogin(false)
        }
    },[showLoginModal])

    useEffect(()=>{
        if ( stateLogin=="login" ) {
            setFormCreate({
                email: "",
                password: "",
                confirm: ""
            })
        }
    },[stateLogin])


    useEffect(()=>{
        if ( stateLogin =="reset" ) {
            setFormReset({
                email: formLogin?.email
            })
        }
    },[stateLogin])

    const onChange = (e:any) => {
        setFormLogin({ ...formLogin, [e.target.name]: e.target.value });
    };

    const onChangeCreate = (e:any) => {
        setFormCreate({ ...formCreate, [e.target.name]: e.target.value });
    };

    const onChangeReset= (e:any) => {
        setFormReset({ ...formReset, [e.target.name]: e.target.value });
    };

    const loginWithCredentials = async() => {
        setForgotPassword(false)
        if ( !formLogin.email || !formLogin.password ) {
            addError(t("authentication.noMailOrPassword"),"error")
        }else if ( ! await signInWithCredentials(formLogin.email, formLogin.password) ) {
            setForgotPassword(true)
        }
    }

    const createUserWithCredentialsFromForm = async() => {
        setShowPasswordNotStrongEnough(false)
        if ( !formCreate.email || !formCreate.password ) {
            addError(t("authentication.noMailOrPassword"),"error")
        }else if( passwordNotStrongEnough(formCreate.password)) {
            setShowPasswordNotStrongEnough(true)
            addError(t("authentication.passwordNotStrongEnough"),"error")
        }else if ( formCreate.password != formCreate.confirm ) {
            addError(t("authentication.passwordConfirmationError"),"error")
        }else if ( ! await createUserWithCredentials(formCreate.email, formCreate.password) ) {
            
        }
    }

    const resetPasswordByEmailForm = async() => {
        if ( !formReset.email ) {
            addError(t("authentication.enterEmail"),"error")
        }else {
            await resetPasswordByEmail(formReset.email)
            addError(t("authentication.passwordResetEmailSent"),"info")
            setStateLogin("login")
        }
    }

    const passwordNotStrongEnough = (password: string) => {
        // Au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return !regex.test(password);
      };

    return(
       <Flex 
            px={5}
            py={2}
            w="full" 
            direction={"column"} 
            gap={2} 
        >
            {stateLogin=="login" && 
            <>
                <Flex fontSize={"sm"} alignSelf={"center"} py={0}>
                    {t("authentication.Vous avez déjà un compte")}
                </Flex>
                <FormControl>
                    <FormLabel fontSize="sm" fontWeight={"bold"}>{t("authentication.Email")}</FormLabel>
                    <Input 
                        size="sm"  
                        type='email' 
                        value={formLogin?.email}
                        name="email"
                        onChange={onChange}
                        placeholder={t("authentication.Entrez votre email")}/>
                </FormControl>
                <FormControl>
                    <FormLabel fontSize={"sm"} fontWeight={"bold"}>{t("authentication.Mot de passe")}</FormLabel>
                    <InputGroup size="sm">
                        <Input
                            type={showPasswordLogin ? "text" : "password"}
                            value={formLogin?.password}
                            name="password"
                            onChange={onChange}
                            placeholder={t("authentication.Entrez votre mot de passe")}
                        />
                        <InputRightElement cursor="pointer" onClick={()=>setShowPasswordLogin(!showPasswordLogin)}>
                            {showPasswordLogin ? <PiEyeSlash /> : <PiEyeLight />}
                        </InputRightElement>
                    </InputGroup>
                    <FormHelperText textAlign="right" onClick={()=>setStateLogin("reset")}  display={forgotPassword?"block":"none"} cursor="pointer" color="#DD6B20" fontSize="sm" fontWeight={"bold"}>{t("authentication.Mot de passe oublié ?")}</FormHelperText>
                </FormControl>
                <Flex
                    w="full"
                    py={3}
                >
                    <Button
                        colorScheme='orange'
                        w="full"
                        onClick={loginWithCredentials}
                    >
                        {t("authentication.Connexion")}
                    </Button>
                </Flex>
                <Flex fontSize={"sm"} alignSelf={"center"} pb={1}>
                    {t("authentication.Vous n'avez pas de compte ?")} <Box cursor="pointer"  onClick={()=>setStateLogin("create")} pl={1} fontWeight={"bold"} color={"#DD6B20"}> {t("authentication.Créez un compte")}</Box>
                </Flex>
                
                {!inAppBrowsing &&  <>
                    <Divider borderColor="lightGrey"/>
                <Flex pt={1} color="black" opacity="0.5" fontSize={"sm"} alignSelf={"center"}>
                    {t("authentication.Ou connectez vous avec")}
                </Flex>
                <Flex
                    w="full"
                    pb={0}
                    pt={0}
                    justifyContent={"space-around"}
                >
                    {/*<Button
                        background="transparent"
                        size="sm"
                        onClick={()=>signInWithFacebook()}
                    >
                        <FaSquareFacebook color="#385898" fontSize="21" /> <Text pl={1}>Facebook</Text>
    </Button>*/}
                    <Button
                        background="transparent"
                        size="sm"   
                        onClick={()=>signInWithGoogle()}        
                    >
                        <FcGoogle fontSize="21" /> <Text pl={1}>Google</Text>
                    </Button>            
                    <Button
                        background="transparent"
                        size="sm"
                        onClick={()=>signInWithTwitter()}
                    >
                        <ImTwitter color="#1DA1F2" fontSize="21"/> <Text pl={1}>Twitter</Text>    
                    </Button>   
                </Flex>
                </>}
                { allowAnonymousLogin && <>
                    <Flex pt={1} color="black" opacity="0.5" fontSize={"sm"} alignSelf={"center"}>
                        {t("authentication.Ou")}
                    </Flex>
                    <Text
                        px={6}
                        pb={2}
                        fontSize={"0.8em"}
                        textAlign={"center"}
                    >
                        <Trans
                            components={{ strong: <strong/>}}
                            i18nKey="authentication.loginExplanation"/>
                    </Text>

                    <Flex
                        width={"full"}
                        justifyContent={"center"}
                    >
                        <Button
                            size="xs"
                            fontFamily={"Roboto,Helvetica,Arial,sans-serif"}
                            fontWeight={"normal"}
                            onClick={()=>anonymousLogin()}
                            background={"brand.104!important"}
                            borderRadius={"2px"}                        
                            justifyContent={"left"}
                        >
                            <Box
                                pr={3}
                                py={2}
                            >
                            <MdPerson size={"18px"}/>
                            </Box>
                            <Text>{t("authentication.continueAsGuest")}</Text>
                        </Button>
                    </Flex>
                </>}
                <Flex pb={6}></Flex>
            </>}

            {stateLogin=="create" && 
            <>
               
                <Flex color="black" opacity="0.5" fontSize={"sm"} alignSelf={"center"}>
                    {t("authentication.Enregistrez vous avec")}
                </Flex>
                {!inAppBrowsing && <>
                <Flex
                    w="full"
                    pb={0}
                    pt={0}
                    justifyContent={"space-around"}
                >
                    {/*<Button
                        background="transparent"
                        size="sm"
                        onClick={()=>signInWithFacebook()}
                    >
                        <FaSquareFacebook color="#385898" fontSize="21" /> <Text pl={1}>Facebook</Text>
                </Button>*/}
                    <Button
                        background="transparent"
                        size="sm"   
                        onClick={()=>signInWithGoogle()}        
                    >
                        <FcGoogle fontSize="21" /> <Text pl={1}>Google</Text>
                    </Button>            
                    <Button
                        background="transparent"
                        size="sm"
                        onClick={()=>signInWithTwitter()}
                    >
                        <ImTwitter color="#1DA1F2" fontSize="21"/> <Text pl={1}>Twitter</Text>    
                    </Button>   
                </Flex>
               
                <Divider borderColor="lightGrey"/>
                <Flex pt={1} color="black" opacity="0.5" fontSize={"sm"} alignSelf={"center"}>
                    {t("authentication.Ou avec un email et mot de passe")}
                </Flex>
                </>}
                <FormControl>
                    <FormLabel fontSize="sm" fontWeight={"bold"}>{t("authentication.Email")}</FormLabel>                   
                    <Input 
                        size="sm"  
                        type='email' 
                        value={formCreate?.email}
                        name="email"
                        onChange={onChangeCreate}
                        placeholder={t("authentication.Entrez votre email")}/>
                </FormControl>
                <FormControl>
                    <FormLabel fontSize={"sm"} fontWeight={"bold"}>{t("authentication.Mot de passe")}</FormLabel>
                    <InputGroup size="sm">
                        <Input
                            type={showPasswordCreate ? "text" : "password"}
                            value={formCreate?.password}
                            name="password"
                            onChange={onChangeCreate}
                            placeholder={t("authentication.Entrez votre mot de passe")}
                        />
                        <InputRightElement cursor="pointer"  onClick={()=>setShowPasswordCreate(!showPasswordCreate)}>
                            {showPasswordCreate ? <PiEyeSlash /> : <PiEyeLight />}
                        </InputRightElement>
                    </InputGroup>
                    <FormHelperText textAlign="right" display={showPasswordNotStrongEnough?"block":"none"} color="red" fontSize="xs" fontWeight={"bold"}>{t("authentication.passwordNotStrongEnough")}</FormHelperText>                    
                </FormControl>
                <FormControl>
                    <FormLabel fontSize={"sm"} fontWeight={"bold"}>{t("authentication.Confirmation")}</FormLabel>
                    <InputGroup size="sm">
                        <Input
                            type={showConfirmCreate ? "text" : "password"}
                            value={formCreate?.confirm}
                            name="confirm"
                            onChange={onChangeCreate}
                            placeholder={t("authentication.Confirmez votre mot de passe")}
                        />
                        <InputRightElement cursor="pointer" onClick={()=>setShowConfirmCreate(!showConfirmCreate)}>
                            {showConfirmCreate ? <PiEyeSlash /> : <PiEyeLight />}
                        </InputRightElement>
                    </InputGroup>                    
                </FormControl>
                {/*<Checkbox size="sm" justifyContent={"center"} w={"full"} isChecked={subscribeNewsletter} onChange={()=>setSubscribeNewsletter(!subscribeNewsletter)}>{t("header.subscribeNewsletter")}</Checkbox>*/}
                <Flex
                    w="full"
                    py={3}
                >
                    <Button
                        colorScheme='orange'
                        w="full"
                        onClick={createUserWithCredentialsFromForm}
                    >
                        {t("authentication.Continuez")}
                    </Button>
                </Flex>
                <Flex fontSize={"sm"} alignSelf={"center"} pb={0}>
                    {t("authentication.Vous avez déjà un compte ?")} <Box cursor="pointer"  onClick={()=>setStateLogin("login")} pl={1} fontWeight={"bold"} color={"#DD6B20"}> {t("authentication.Connectez vous")}</Box>
                </Flex>
                { allowAnonymousLogin && <>
                    <Flex pt={1} color="black" opacity="0.5" fontSize={"sm"} alignSelf={"center"}>
                        {t("authentication.Ou")}
                    </Flex>
                    <Text
                        px={6}
                        pb={2}
                        fontSize={"0.8em"}
                        textAlign={"center"}
                    >
                        <Trans
                            components={{ strong: <strong/>}}
                            i18nKey="authentication.loginExplanation"/>
                    </Text>

                    <Flex
                        width={"full"}
                        justifyContent={"center"}
                    >
                        <Button
                            size="xs"
                            fontFamily={"Roboto,Helvetica,Arial,sans-serif"}
                            fontWeight={"normal"}
                            onClick={()=>anonymousLogin()}
                            background={"brand.104!important"}
                            borderRadius={"2px"}                        
                            justifyContent={"left"}
                        >
                            <Box
                                pr={3}
                                py={2}
                            >
                            <MdPerson size={"18px"}/>
                            </Box>
                            <Text>{t("authentication.continueAsGuest")}</Text>
                        </Button>
                    </Flex>
                </>}
                <Flex pb={6}></Flex>
            </>}
            {stateLogin=="reset" && 
            <>
                <Flex fontWeight={"bold"} alignSelf={"center"}>
                    {t("authentication.Réinitialiser le mot de passe")}
                </Flex>
                <Flex color="black" opacity="0.5" fontSize={"sm"} alignSelf={"center"}>
                    {t("authentication.resetPasswordExplanation")}
                </Flex>
                <FormControl>
                    <FormLabel fontSize="sm" fontWeight={"bold"}>{t("authentication.Email")}</FormLabel>
                    <Input 
                        size="sm"  
                        type='email' 
                        value={formReset?.email}
                        name="email"
                        onChange={onChangeReset}
                        placeholder={t("authentication.Entrez votre email")}/>
                </FormControl>
                <Flex
                    w="full"
                    py={3}
                >
                    <Button
                        colorScheme='orange'
                        w="full"
                        onClick={resetPasswordByEmailForm}
                    >
                        {t("authentication.Continuez")}
                    </Button>
                </Flex>
                <Flex fontSize={"sm"} alignSelf={"center"} pb={6}>
                    <Box cursor="pointer"  onClick={()=>setStateLogin("login")} pl={1} fontWeight={"bold"}> {t("authentication.Retour")}</Box>
                </Flex>
            </>}
       </Flex>
    )
}
