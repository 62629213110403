import { parse } from "path";

const getCurrentDate = () => {
  const today = new Date();
  return today.toISOString().split('T')[0]; // Format "YYYY-MM-DD"
};

// Supprimer les anciennes données dans localStorage
export const clearOldDataLocalStorageHelper = () => {
  localStorage.removeItem('dailyItems');
};

export const storeDataWithDateLocalStorageHelper = ( value:any) => {
  const key='dailyItems'
  // D'abord, vider les anciennes données
  clearOldDataLocalStorageHelper();

  // Ensuite, stocker les nouvelles données avec la date du jour
  const jsonValue = JSON.stringify({
    data: value,
    date: getCurrentDate(),
  });
  localStorage.setItem(key, jsonValue);
};

export const getDataLocalStorageHelper = () => {
  const storedData = localStorage.getItem('dailyItems');
  const currentDate = getCurrentDate();

  if (storedData) {
    const parsedData = JSON.parse(storedData);

    // Si la date correspond à aujourd'hui, ne pas renouveler les données
    if (parsedData.date === currentDate && parsedData.data && parsedData.data.length > 0) {
      return parsedData.data.filter((item:any)=>item.expiresAt>=new Date().getTime() ); // Retourner les données existantes
    }
  }
  return false
}