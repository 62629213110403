import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";
import { ActionHistoryType } from "../type/actionHistoryType";
import { v4 } from "uuid";
import { GlobalDataContext } from "../globalDataContext";
import { formatDateAAAAMMJJ } from "@/app/helpers/getFormatDate";

export const useActionHistory = () => {
    const initialState:ActionHistoryType[] = []
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, isSigning, logOut, isNewUser,  cloudStorage, wasUserNotConnected} = useContext(AuthenticationContext)
    const {browserActive} = useContext(GlobalDataContext)
    const dataFromNotConnecterRef = useRef<ActionHistoryType[]|null>(null);

    type Action =
        | { type: 'ADD'; payload: ActionHistoryType[] }
        | { type: 'UPDATE'; payload: ActionHistoryType[] }
        | { type: 'ADD_FROM_CLOUD'; payload: ActionHistoryType[] }
        | { type: 'UPDATE_FROM_CLOUD'; payload: ActionHistoryType[] }
        | { type: 'DELETE'; payload: ActionHistoryType[] }
        | { type: 'EMPTY' }


    const reducer= (state:any, action:Action) => {
        switch (action.type) {
            case 'ADD':
                return [
                    ...state, 
                    ...action.payload.map((item: ActionHistoryType) => ({
                        ...item,
                        isSynchronized: false,
                    }))
                ]; 
            case 'UPDATE':
                return state.map((item: any) => {
                        const updatedItem = action.payload.find((action:ActionHistoryType) => action.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: false };
                        }
                        return item;
                    });
            case 'ADD_FROM_CLOUD':
                return [
                    ...state, 
                    ...action.payload.map((item: ActionHistoryType) => ({
                        ...item,
                        isSynchronized: true,
                    }))
                ]; 
            case 'UPDATE_FROM_CLOUD':
                return state.map((item: ActionHistoryType) => {
                        const updatedItem = action.payload.find((action:ActionHistoryType) => action.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: true };
                        }
                        return item;
                    });
            case 'DELETE':
                return state.filter((item: ActionHistoryType) => !action.payload.find((action:ActionHistoryType) => action.id === item.id)) ;

            case 'EMPTY':
                return [];

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
        if ( process.env.NODE_ENV==="development" ) {
            console.log("actionhistory change", state)
        }
    }, [state]);

    const add=(datas: ActionHistoryType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'ADD', 
            payload: datas})
    }

    const update=(datas: ActionHistoryType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'UPDATE', 
            payload: datas})
    }
    
    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    const updateByDay=(type:"connection" | "createAccount" | "careEgg" | "newEgg" | "feed" | "trade" | "buyFood" | "play" | "laugh" | "exchangeCard" | "newCreature" | "kilometer" | "friends" | "zone" | "welcome", count:number=1)=>{

        if ( !browserActive ) { return }
        
        const today = new Date();

        const index = state.findIndex((action:any) => 
            action.type === type && 
            action.date.getFullYear() === today.getFullYear() &&
            action.date.getMonth() === today.getMonth() &&
            action.date.getDate() === today.getDate()
        );
        if (index !== -1) {
            update([{
                ...state[index],
                count: state[index].count + count,
                isSynchronized: false
            }])
        } else {
            const date = new Date();
            add([{
                id: formatDateAAAAMMJJ(date.getTime()) + type,
                date: date,
                type,
                count,
                isSynchronized: false
            }])
        }        
    }

    const checkActionHistory=(type:"connection" | "createAccount" | "feed" | "trade" | "careEgg" | "buyFood" | "play" | "exchangeCard" | "newEgg" | "newCreature" | "kilometer" | "friends" | "zone" | "welcome")=>{
        const today = new Date();
        const check = state?.some((action:ActionHistoryType )=> 
            action.type === type && 
            action.date.getFullYear() === today.getFullYear() &&
            action.date.getMonth() === today.getMonth() &&
            action.date.getDate() === today.getDate()
        )
        return check || false
    }

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
           /* const unsubscribe = cloudStorage?.subscribeToActionHistory(
                (actionHistory)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("unsubscribeMyCreatures", actionHistory)
                    }
                    if ( actionHistory && actionHistory.length>0 ) {
                        const itemsToAdd = actionHistory?.filter(
                            (item:ActionHistoryType) => !stateRef.current.some((stateItem: ActionHistoryType) => stateItem.id === item.id)
                        ) || [];
                        if ( itemsToAdd && itemsToAdd.length > 0 ){
                            dispatch({
                                type: 'ADD_FROM_CLOUD', 
                                payload: itemsToAdd})
                        }

                        const updatedItemsState = actionHistory
                        .filter((item:ActionHistoryType) => stateRef.current.some((stateItem: ActionHistoryType) => stateItem.id === item.id)) || []
                
                        if ( updatedItemsState && updatedItemsState.length > 0 ){
                            dispatch({
                                type: 'UPDATE_FROM_CLOUD', 
                                payload: updatedItemsState})
                        }
                    }
                },
                setIsLoading
            )*/

            cloudStorage?.getActionHistoryFromCloud().then((actionHistory)=>{
                if (actionHistory && actionHistory.length > 0) {
                    dispatch({
                        type: 'ADD_FROM_CLOUD',
                        payload: actionHistory
                    });
                }
            })
/*
            const unsubscribe = cloudStorage?.subscribeToActionHistory(
                (actionHistory) => {
                    if (process.env.NODE_ENV === "development") {
                        console.log("unsubscribeMyCreatures", actionHistory);
                    }
            
                    if (actionHistory && actionHistory.length > 0) {
                        // Normaliser la date pour ignorer les heures lors des comparaisons
                        const normalizeDate = (date: Date) => {
                            const normalized = new Date(date);
                            normalized.setHours(0, 0, 0, 0);
                            return normalized;
                        };
            
                        // Filtrer les nouveaux items à ajouter qui n'existent pas déjà dans le state
                        const itemsToAdd = actionHistory?.filter((item: ActionHistoryType) => {
                            const itemDate = normalizeDate(item.date);
                            return !stateRef.current.some(
                                (stateItem: ActionHistoryType) =>
                                    stateItem.id === item.id ||
                                    (stateItem.type === item.type && normalizeDate(stateItem.date).getTime() === itemDate.getTime())
                            );
                        }) || [];
            
                        if (itemsToAdd.length > 0) {
                            dispatch({
                                type: 'ADD_FROM_CLOUD',
                                payload: itemsToAdd
                            });
                        }
            
                        // Filtrer les items à mettre à jour, en incrémentant la valeur de count
                        const updatedItemsState = actionHistory?.map((item: ActionHistoryType) => {
                            const itemDate = normalizeDate(item.date);
                            const existingItem = stateRef.current.find(
                                (stateItem: ActionHistoryType) =>
                                    stateItem.type === item.type && normalizeDate(stateItem.date).getTime() === itemDate.getTime()
                            );
            
                            if (existingItem) {
                                return {
                                    ...existingItem,
                                    count: existingItem.count + item.count, // Incrémenter le count
                                    isSynchronized: false
                                };
                            }
                            return null;
                        }).filter(Boolean); // Filtrer les éléments nulls
            
                        if (updatedItemsState.length > 0) {
                            dispatch({
                                type: 'UPDATE_FROM_CLOUD',
                                payload: updatedItemsState
                            });
                        }
                    }
                },
                setIsLoading
            );
*/
            return () => {
               // if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])


    useEffect(()=>{
        if ( cloudStorage && user && state && state.length > 0 ){
            setIsLoading(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setActionHistoryToCloud preSave")
            }
            const unsynchronizedItems = state.filter((item:ActionHistoryType)=>!item.isSynchronized)
            if ( unsynchronizedItems && unsynchronizedItems.length > 0 ){
                cloudStorage?.setActionHistoryToCloud(unsynchronizedItems).then((result)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("setActionHistoryToCloud", result)
                    }
                })
            }
        }
    }, [cloudStorage, user, state])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    useEffect(()=>{
        if (isSigning) {
            if ( wasUserNotConnected){                
                dataFromNotConnecterRef.current = state                
            }       
            emptyState()
            setIsLoading(true)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wasUserNotConnected", state)
            }
        }
    },[isSigning, wasUserNotConnected])

    useEffect(()=>{
        if ( !isLoading && user && isNewUser && !state.length && dataFromNotConnecterRef.current && dataFromNotConnecterRef.current.length > 0 ){
            add(dataFromNotConnecterRef.current)
            dataFromNotConnecterRef.current = null
            if ( process.env.NODE_ENV==="development" ) {
                console.log("add data from wasUserNotConnected", dataFromNotConnecterRef.current)
            }
        }
    },[isLoading, user ])

    useEffect(()=>{
        if ( logOut ){
            emptyState()
            dataFromNotConnecterRef.current = null
        }
    },[logOut])

    return {
        state,
        isLoading,
        updateByDay,
        checkActionHistory
    }
}