import { useRef, useEffect, useCallback, useMemo } from 'react';

type AudioFiles = { id: string; src: string }[];

const useAudio = (audioFiles: AudioFiles) => {
    const audioRefs = useRef<{ [key: string]: HTMLAudioElement }>({});

  // Mémoriser les fichiers audio pour éviter les ré-exécutions inutiles
  const memoizedAudioFiles = useMemo(() => audioFiles, [audioFiles]);

  useEffect(() => {
    // Initialiser les fichiers audio, mais uniquement si non déjà présents
    memoizedAudioFiles.forEach(({ id, src }) => {
      if (!audioRefs.current[id]) {
        try {
          audioRefs.current[id] = new Audio(src);
        }catch(e){
            //console.log(e);
        }
      }
    });

    const localRef = audioRefs.current;
    return () => {
      // Nettoyage des fichiers audio si nécessaire
      Object.values(localRef).forEach((audio) => {
        audio.pause();
        audio.src = ""; // Libérer la mémoire en vidant la source
      });
    };
  }, [memoizedAudioFiles]); // Ne se déclenche que lorsque memoizedAudioFiles change

  const play = useCallback((id: string) => {
    const audio = audioRefs.current[id];
    try {
      if (audio) {
        audio.currentTime = 0; // Revenir au début si nécessaire
        
        // Vérifie si l'audio est prêt à être joué
        if (audio.readyState >= 2) { // 2 = HAVE_CURRENT_DATA (l'audio est prêt)
          audio.play();
      } else {
          // Recharge l'audio s'il n'est pas prêt
          audio.load();
          audio.play();
      }
      }
    }catch(e){
      //console.log(e);
    }
  }, []);

  return { play };
};


export default useAudio;