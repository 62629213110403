import { useContext } from "react";
import { Button, Text, Flex, Input, useDisclosure } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { PaymentContext } from "@/app/context/payment/paymentContext";
import { buildApiStripeCheckouUrl } from "@/app/helpers/buildUrlHelper";
import { ContentPriceType } from "../type/contentPriceType";
import { GlobalDataUserContext } from "../../global/globalDataUserContext";
import { GiCupcake } from "react-icons/gi";
import { remove } from "lodash";
import { ConfirmBuyItem } from "./modal/confirmBuyItem";

type Props = {
    categoryId?:number, 
    content: ContentPriceType
}

export const ButtonPaymentCupcake = ({categoryId, content }:Props) => {
    const router = useRouter();
    const { language } = router.query;
    const {t} = useTranslation();

    const {
        user
    } = useContext(AuthenticationContext)

    const {
        setErrorPayment,
        setValidPayment,
        addItemForPayment
    } = useContext(PaymentContext)

    const {
        userState,
        removeFoodReducer
    } = useContext(GlobalDataUserContext)

    const handleCheckout=async()=>{
        removeFoodReducer(content.price)
        setValidPayment(true)        
        addItemForPayment(content,"cupcake")
    }

    const {isOpen, onOpen, onClose} = useDisclosure()
    
    return(
        <>
            {userState && userState?.food > content.price && <Flex
                flexDirection={"column"}
            >
            <Button
                type={"submit"}
                background="#f16529!important" 
                color="white!important" 
                m={0}
                ml={3}
                onClick={onOpen}
                alignItems={"flex-start"}
                p={3}
            >
                <Text pr={2}>{t("payment.doPay")} : {content.price} </Text><GiCupcake/>
            </Button>            
            </Flex>}
            {isOpen && <ConfirmBuyItem item={content} isOpen={isOpen} onClose={onClose} onValidate={handleCheckout} />}
        </>
    )
}
