import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";
import { UserStateType } from "@/app/types/userStateType";
import { debounce } from "lodash";

export const useUserState = () => {
    const initialState:UserStateType = {} as UserStateType
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, isSigning, logOut, isNewUser, cloudStorage, wasUserNotConnected, messagingToken} = useContext(AuthenticationContext)
    const dataFromNotConnecterRef = useRef<UserStateType|null>(null);

    type Action =
        | { type: 'ADD_POINTS'; payload: { points: number } }
        | { type: 'ADD_XP'; payload: { xp: number } }
        | { type: 'SET_GOOBLIES'; payload: number }
        | { type: 'ADD_BADGE'; payload: { id: string } }
        | { type: 'GOT_BONUS_FROM_BADGE'; payload: { id: string } }
        | { type: 'ADD_KILOMETERS'; payload: number }
        | { type: 'ADD_FOOD'; payload: number }
        | { type: 'REMOVE_FOOD'; payload: number }
        | { type: 'UPDATE_ALL'; payload: UserStateType }
        | { type: 'UPDATE_FROM_CLOUD'; payload: UserStateType }
        | { type: 'EMPTY' }


    const reducer= (state:any, action:Action) => {
        switch (action.type) {
            case 'ADD_POINTS':
                return {
                    ...state,
                    points: (state.points??0) + action.payload.points,
                    isSynchronized: false,
                };
            case 'ADD_XP':
                return {
                    ...state,
                    xp: (state.xp??0) + action.payload.xp,
                    isSynchronized: false,
                };
            case 'SET_GOOBLIES':
                return {
                    ...state,
                    gooblies: action.payload,
                    isSynchronized: false,
                };
            case 'ADD_BADGE':
                return {
                    ...state,
                    badges: [
                        ...state.badges||[],
                        {
                            id: action.payload.id,
                            dateUnlocked: Date.now(),
                            gotBonus: false,
                        },
                    ],
                    isSynchronized: false,
                };
            case 'GOT_BONUS_FROM_BADGE':
                return {
                    ...state,
                    badges: (state.badges||[]).map((badge:any) => {
                        if (badge.id === action.payload.id) {
                            return {
                                ...badge,
                                gotBonus: true,
                            };
                        }
                        return badge;
                    }),
                    isSynchronized: false,
                };
            case 'ADD_KILOMETERS':
                return {
                    ...state,
                    kilometers:( state.kilometers??0 )+ action.payload,
                    isSynchronized: false,
                };
            case 'ADD_FOOD':
                return {
                    ...state,
                    food: (state.food??0 )+ action.payload,
                    isSynchronized: false,
                };
            case 'REMOVE_FOOD':
                return {
                    ...state,
                    food: ((state.food??0) - action.payload)>=0 ? state.food - action.payload : 0,
                    isSynchronized: false,
                };
            case 'UPDATE_ALL':
                return { ...action.payload, isSynchronized: false };
            case 'UPDATE_FROM_CLOUD':
                return { ...action.payload, isSynchronized: true };                
            case 'EMPTY':
                return {} as UserStateType;

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);

    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
        if ( process.env.NODE_ENV==="development" ) {
            console.log("userstate change", state)
        }
    }, [state]);

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
            const unsubscribe = cloudStorage?.subscribeToUserState(
                (status)=>{
                    if ( status ){

                        const seen = new Set();
                        const cleanedBadges = (status.badges||[]).filter(badge => {
                            if (seen.has(badge.id)) {
                                return false; // Ignorer les doublons
                            }
                            seen.add(badge.id); // Enregistrer cet id comme vu
                            return true; // Conserver le premier badge rencontré
                        });

                        // Création d'un nouvel état avec les badges nettoyés
                        const cleanedUserState = {
                            ...status,
                            badges: cleanedBadges // Utilisation des badges sans doublons
                        };

                        dispatch({
                            type: 'UPDATE_FROM_CLOUD', 
                            payload: cleanedUserState})
                    }
                },
                setIsLoading
            )

            return () => {
                if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    const saveUserState = debounce(() => {        
        cloudStorage?.setUserStateToCloud(state).then()
    }, 2500)
  
    useEffect(()=>{
        if ( cloudStorage && user && state &&  Object.keys(state).length !== 0 ){
            setIsLoading(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setActionHistoryToCloud preSave")
            }
            if ( !state.isSynchronized){
                saveUserState()
            }
            return ()=>{
                saveUserState.cancel()
            }
        }
    }, [cloudStorage, user, state])

    useEffect(()=>{
        if (isSigning) {
            if ( wasUserNotConnected){      
                dataFromNotConnecterRef.current = state                
            }       
            emptyState()
            setIsLoading(true)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wasUserNotConnected", dataFromNotConnecterRef.current,state)
            }
        }
    },[isSigning, wasUserNotConnected])

    useEffect(()=>{
        if ( logOut ){
            emptyState()
            dataFromNotConnecterRef.current = null
        }
    },[logOut])

    useEffect(()=>{
        if ( !isLoading && user && isNewUser && ( !state || state.points == 0) && dataFromNotConnecterRef.current && Object.keys(dataFromNotConnecterRef.current).length !== 0 ){
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wasUserNotConnected data to save", dataFromNotConnecterRef.current)
            }
            //updateAll({...dataFromNotConnecterRef.current,userId:user.uid})
            if ( dataFromNotConnecterRef.current.badges && dataFromNotConnecterRef.current.badges.length > 0 ){
                dataFromNotConnecterRef.current.badges.forEach((badge:any)=>{
                    addBadge(badge.id)
                })
            }
            if ( dataFromNotConnecterRef.current.food && dataFromNotConnecterRef.current.food > 0 ){
                addFood(dataFromNotConnecterRef.current.food)
            }
            if ( dataFromNotConnecterRef.current.kilometers && dataFromNotConnecterRef.current.kilometers > 0 ){
                addKilometers(dataFromNotConnecterRef.current.kilometers)
            }
            if ( dataFromNotConnecterRef.current.points && dataFromNotConnecterRef.current.points > 0 ){
                addPoints(dataFromNotConnecterRef.current.points)
            }
            if ( dataFromNotConnecterRef.current.xp && dataFromNotConnecterRef.current.xp > 0 ){
                addXp(dataFromNotConnecterRef.current.xp)
            }
            if ( dataFromNotConnecterRef.current.gooblies && dataFromNotConnecterRef.current.gooblies > 0 ){
                setGooblies(dataFromNotConnecterRef.current.gooblies)
            }
            
            dataFromNotConnecterRef.current = null
        }
    },[isLoading, user ])

    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    const updateAll=(state:UserStateType)=>{
        if ( !state ||  !Object.keys(state).length ) { return }
        dispatch({
            type: 'UPDATE_ALL', 
            payload: state})
    }

    const addPoints=(points:number)=>{
        dispatch({
            type: 'ADD_POINTS', 
            payload: { points }})
    }

    const addXp=(xp:number)=>{
        dispatch({
            type: 'ADD_XP', 
            payload: { xp }})
    }

    const setGooblies=(gooblies:number)=>{
        dispatch({
            type: 'SET_GOOBLIES', 
            payload: gooblies})
    }

    const addBadge=(badgeId:string)=>{
        dispatch({
            type: 'ADD_BADGE', 
            payload: {id: badgeId}})
    }

    const gotBonusFromBadge=(badgeId:string)=>{
        dispatch({
            type: 'GOT_BONUS_FROM_BADGE', 
            payload: { id: badgeId }})
    }
    const addKilometers=(kilometers:number)=>{
        dispatch({
            type: 'ADD_KILOMETERS', 
            payload: kilometers})
    }

    const addFood=(food:number)=>{
        dispatch({
            type: 'ADD_FOOD', 
            payload: food})
    }

    const removeFood=(food:number)=>{
        dispatch({
            type: 'REMOVE_FOOD', 
            payload: food})
    }

    return {
        state,
        isLoading,
        addPoints,
        addXp,
        setGooblies,
        addBadge,
        addKilometers,
        addFood,
        removeFood,
        gotBonusFromBadge
    }
}