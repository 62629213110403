import { ContentPriceList } from '../const/contentPriceList';
import { ContentPriceType } from '../context/payment/type/contentPriceType';
import { PaymentModalType } from '../context/payment/type/paymentModalType';

export const buildPaymentModalTypeFromType=(type:string, categoryId?:number):PaymentModalType|false=>{
    const payment = ContentPriceList.find((payment: ContentPriceType) => payment.id === type);

    const item = payment ? {
        itemId: payment.id,
        categoryId: categoryId
    } : false;
    return item
}