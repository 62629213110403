import { CreatureCategoryType } from "../types/creatureCategoryType";

export const CreatureCategoryConst : CreatureCategoryType[] = [
    {
        id: 1,
        url: 'monster',
        name: 'Grossy Monsters',
        action: 'Chase Monsters'
    },
    
] 
