import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { FirebaseContext } from "@/app/context/firebase/firebaseContext";
import { FriendsContext } from "@/app/context/friends/friendsContext";
import { GlobalDataUserContext } from "@/app/context/global/globalDataUserContext";
import { NotificationContext } from "@/app/context/notification/notificationContext";
import useFriendRequests from "@/app/hooks/useFriendRequests";
import { FocusableElement } from "@chakra-ui/dom-utils";
import { Button, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import React, { Dispatch, MouseEventHandler, MutableRefObject, useContext, useState } from "react";

type Props = {
    isOpen: boolean
    onClose: MouseEventHandler<HTMLButtonElement>
    setLogOut: Dispatch<React.SetStateAction<boolean>>
}

export const SearchBarAddFriend = () => {
    const cancelRef: MutableRefObject<FocusableElement | undefined> = React.useRef()
    const { t } = useTranslation();
    
    const [searchInput,setSearchINput] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const {
        addOrUpdateActionHistory
    } = useContext(GlobalDataUserContext)

    const {
        user,
        cloudStorage,
        sendNotificationPush
    } = useContext(AuthenticationContext)

    const {
        setShowModalInviteFriendsOutside,
        setFriendEmail
    } = useContext(FriendsContext)

    const {
        logAnalyticsEvent
    } = useContext(FirebaseContext)

    const {
        addError
    } = useContext(NotificationContext)

    const {searchUser} = useFriendRequests()

    const applySearchFilter = (e: { target: { value: React.SetStateAction<string | undefined>; }; }) => {
        setFriendEmail(e.target.value as string)
        setSearchINput(e.target.value as string)
    }

    const isValidEmail = (email:string)=> {
        const emailRegex =  /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const checkMail=async ()=>{
        setIsLoading(true)

        if ( !isValidEmail(searchInput.toLowerCase())){
            setIsLoading(false)
            addError(t("friends.emailNotValid"),"error")
            return
        }

        const userFromAuth = await searchUser(searchInput.toLowerCase())
        //const userEmail = await cloudStorage?.searchEmailInUsers(searchInput)

        if ( userFromAuth && userFromAuth.id && userFromAuth.id !== -1) {
            if ( user?.uid == userFromAuth.id){
                addError(t("friends.cantAddYourself"),"error")
            }else {
                await cloudStorage?.addFriendRequests(userFromAuth.id)
                try{
                    addOrUpdateActionHistory("friends",1)
                    logAnalyticsEvent("friendRequestSent",{})
                    await sendNotificationPush({
                        userId : userFromAuth.id, 
                        title: t("notifications.title"), 
                        body: t("notifications.newFriendRequest")})
                } catch (error: any) {                
                }
                addError(t("friends.friendRequestSent"),"info")
            }
        }else{
            setShowModalInviteFriendsOutside(true)
        }
        setSearchINput("")
        setIsLoading(false)
    }
    
    return(
        <InputGroup size='md' my={2} background={"#fff"}>
            <Input
                pr='4.5rem'
                type={"text"}
                placeholder={t("friends.addViaEmail")}
                value={searchInput}
                onChange={applySearchFilter}
            />
            <InputRightElement width='4.5rem' marginRight={1}>
                <Button 
                    h='1.75rem' 
                    size='sm' 
                    disabled={isLoading}
                    opacity={isLoading?0.25:1}
                    cursor={isLoading?"not-allowed":"pointer"}
                    onClick={!isLoading?checkMail:()=>{}}
                >
                    {t("friends.add")}
                </Button>
            </InputRightElement>
        </InputGroup>
    )
}
