import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import { CreatureDefinitionType } from "@/app/types/creatureDefinitionType";
import {
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, Image,
    Box,
    Flex
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { keyframes, css } from '@emotion/react';
const zoomAndShake = keyframes`
  0% {
   -webkit-transform: scale(0.9);
    transform: scale(0.9);
    transform-origin: center;
    opacity: 0;
  }
  50% {
   -webkit-transform: scale(1.2);
    transform: scale(1.2);
    transform-origin: center;
    opacity: 1;
  }
  100% {
   -webkit-transform: scale(1);
    transform: scale(1);
    transform-origin: center;
    opacity: 1;
  }
`;

type Props = {
    type: string|null;
    isOpen: boolean;
    onClose: () => void;
    description?: string;
};

export default function NewEggModal({type,isOpen,onClose, description}:Props) {

    const { t } = useTranslation();
    const {
        showLoginModal,
        setShowLoginModal,
        user,
        //subscribeNewsletter,
        //setSubscribeNewsletter
    } = useContext(AuthenticationContext)
    
    return (
        <Modal isCentered  isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxH="80vh" overflow={"hidden"} m={5}  css={css`
                    animation: ${zoomAndShake} 0.7s ease-out;
                `}>
                <ModalHeader px={0} pt={2} pb={0} textAlign={"center"}>{t("game.congratulations")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody px={2} pt={0} pb={2} overflow="auto">
                    {!description &&<Text textAlign={"center"}>{t("game.youGotAEgg")}</Text>}
                    {description &&<Text textAlign={"center"}>{description}</Text>}
                    {type && <>
                        <Box position="relative"  border={type=="LEGENDARY"?"10px solid transparent":"transparent"}
                            background={type=="LEGENDARY"?"linear-gradient(to right, #BF953F, #FCF6BA, #ffd476, #FBF5B7, #b2945e)":"white"}>
                            <Image src={"/images/eggs_" + type +".jpg"} alt={"egg"} />
                            <Flex 
                                position="absolute"
                                bottom={5}
                                w="full"
                                justifyContent={"center"}
                            >
                                <Text 
                                    background={type=="LEGENDARY"? "#ffc337":"#00000099"} 
                                    px={4} 
                                    py={2} 
                                    fontSize={"1.5em"} 
                                    borderRadius={10} 
                                    fontWeight="bold" 
                                    color={type=="LEGENDARY"?"black":"white"}
                                    lineHeight={"normal"}
                                >
                                    {type.toUpperCase()}
                                </Text>
                            </Flex>
                        </Box>  
                    </>}                      
                </ModalBody>
                <ModalFooter px={2} pt={0} pb={2} display="flex">
                        <Button
                            background="#f16529" 
                            color="white"
                            onClick={onClose}
                            w="full"
                        >
                            {t("game.close")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )

}