import { LevelConst } from "../const/levelConst"

const levelNames = [
"Chasseur Novice",
"Apprenti Chasseur",
"Chasseur en Herbe",
"Chasseur Amateur",
"Chasseur Confirmé",
"Chasseur Expérimenté",
"Chasseur Élite",
"Maître Chasseur",
"Chasseur Légendaire",
"Grand Chasseur des Légendes"
]

export const getLevel= (points: number) =>
{
    let result = 9

    for ( let i=0; i< LevelConst.length; i++) {
        if ( points <= LevelConst[i]){
            result = i
            break
        }
    }
    return result
}

export const getLevelName= (points: number) =>
{
    return levelNames[getLevel(points)]
}

export const getLiveScoreLevel= (points: number) =>
{
    let result = 0
    const MAX_SCORE = 600
    if ( points>= MAX_SCORE){
        return 10
    }else {
        result = ( points / MAX_SCORE ) * 10
    }

    return result
}