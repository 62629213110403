import { AuthenticationContext } from "@/app/context/authentication/authenticationContext";
import {
    Button, Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text, VStack,
    FormControl,
    FormLabel,
    Input,
    UnorderedList,
    ListItem
} from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import { useContext, useEffect, useState } from "react";

type Props = {
    isOpen: boolean;
    onClose: () => void;
};

export default function ValidateUserNameModal({isOpen,onClose}:Props) {

    const { t } = useTranslation();
    const [username, setUsername] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [suggestions, setSuggestions] = useState<string[]>([]);
    const {
        showLoginModal,
        setShowLoginModal,
        user,
        cloudStorage,
        messagingToken,
        updateProfileName
        //subscribeNewsletter,
        //setSubscribeNewsletter
    } = useContext(AuthenticationContext)
    

    const extractUsernameFromEmail=(email:string)=> {
        if (typeof email !== 'string' || !email.includes('@')) {
            return ""
        }
    
        // Extraire la partie avant le @
        const username = email.split('@')[0];
    
        return username;
    }

    useEffect(()=>{
        if (user && user.email) {
            setUsername(extractUsernameFromEmail(user.email));
        }
    },[user])

    const checkUsername = async () => {
        if (username.length < 3 || username.length > 20) {
            setErrorMessage(t("authentication.pseudoNotValid"));
            return;
        }
        const check = await cloudStorage?.isUsernameAvailable(username)
        if (check) {
            await cloudStorage?.setUserInfoToCloud({
                id: user?.uid || '',
                token: messagingToken || '',
                photoUrl: user?.photoURL || '',
                userName: username
            })
            updateProfileName(username,()=>{});
            onClose();
            return true
        }else {
            setErrorMessage(t("authentication.pseudoAlreadyUsed"));
            const suggestionsCloud = await cloudStorage?.generateSuggestions(username);
            setSuggestions(suggestionsCloud || []);
        }
        
        return false
    }

    return (
        <Modal closeOnOverlayClick={false} isCentered isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent maxH="80vh" overflow={"hidden"} m={5}>
                <ModalHeader px={0} pt={2} pb={0} textAlign={"center"}>{t("authentication.finish")}</ModalHeader>
                <ModalCloseButton />
                <ModalBody  overflow="auto">
                    <VStack spacing={4} align="stretch">
                        <Text>
                        {t("authentication.finishExplanation")}
                        </Text>
                        <FormControl>
                        <FormLabel>{t("authentication.pseudo")}</FormLabel>
                        <Input
                            type="text"
                            value={username}
                            border={errorMessage ? '1px solid red' : '1px solid #CBD5E0'}
                            onChange={(e) => {
                                setUsername(e.target.value);
                                setErrorMessage('');
                                setSuggestions([]);
                            }}
                            placeholder={t("authentication.enterPseudo")}
                        />
                        </FormControl>
                        {errorMessage && <Text color="red.500">{errorMessage}</Text>}
                        {suggestions.length > 0 && (
                        <VStack align="start">
                            <Text>{t("authentication.pseudoSuggestions")}</Text>
                            <UnorderedList>
                            {suggestions.map((suggestedName) => (
                                <ListItem
                                key={suggestedName}
                                cursor="pointer"
                                onClick={() => {
                                    setUsername(suggestedName);
                                    setErrorMessage('');
                                    setSuggestions([]);
                                }}
                                >
                                <Button size={"sm"} my={1}>
                                    {suggestedName}
                                </Button>
                                </ListItem>
                            ))}
                            </UnorderedList>
                        </VStack>
                        )}
                    </VStack>
                    
                </ModalBody>
                <ModalFooter px={2} pt={10} pb={2} display="flex">
                        <Button
                            colorScheme="blue"
                            onClick={checkUsername}
                            w="full"
                        >
                            {t("authentication.save")}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
    )

}