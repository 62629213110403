import { Flex, HStack } from "@chakra-ui/react";
import { useTranslation } from "next-i18next";
import {
    FacebookIcon,
    FacebookShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    RedditIcon,
    RedditShareButton,
    TelegramIcon,
    TelegramShareButton,
    TwitterIcon,
    TwitterShareButton,
    WhatsappIcon,
    WhatsappShareButton
} from "react-share";

type Props = {
    url?: string
}

export default function ShareSocial({url}:Props) {

    const { t } = useTranslation("common");


    return (
        <HStack>
            <Flex
                pl={0}
                px={1}
                paddingInlineStart={0}
            >
                <FacebookShareButton                    
                    url={url||t("sharing.url")}
                    hashtag={t("sharing.hashtag") as string}
                >
                    <FacebookIcon size={32} round />
                </FacebookShareButton>
            </Flex>
            <Flex
                px={1}
            >
                <TwitterShareButton
                    url={url||t("sharing.url")}
                >
                    <TwitterIcon size={32} round />
                </TwitterShareButton>
            </Flex>
            <Flex
                px={1}
            >
                <WhatsappShareButton
                    url={url||t("sharing.url")}
                    title={t("sharing.title") as string}
                >
                    <WhatsappIcon size={32} round />
                </WhatsappShareButton>
            </Flex>
            <Flex
                px={1}
            >
                <LinkedinShareButton
                    url={url||t("sharing.url")}
                    title={t("sharing.title") as string}
                    source={t("sharing.source") as string}
                    summary={t("sharing.quote") as string}
                >
                    <LinkedinIcon size={32} round />
                </LinkedinShareButton>
            </Flex>
            {/*<Flex
                px={1}
            >
                <RedditShareButton
                    url={t("sharing.url")}
                    title={t("sharing.title") as string}
                >
                    <RedditIcon size={32} round />
                </RedditShareButton>
            </Flex>*/}
            <Flex
                px={1}
            >
                <TelegramShareButton
                    url={url||t("sharing.url")}
                    title={t("sharing.title") as string}
                >
                    <TelegramIcon size={32} round />
                </TelegramShareButton>
    </Flex>
        </HStack>
    )
}
