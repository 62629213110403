import { MyCreaturesType } from "@/app/types/myCreaturesType";
import { useContext, useEffect, useReducer, useRef, useState } from "react";
import { AuthenticationContext } from "../../authentication/authenticationContext";

export const useMyCreatures = () => {
    const initialState:MyCreaturesType[] = []
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const {user, isAuthLoading, isSigning, isNewUser, logOut, cloudStorage, wasUserNotConnected} = useContext(AuthenticationContext)
    const dataFromNotConnecterRef = useRef<MyCreaturesType[]|null>(null);

    type Action =
        | { type: 'ADD'; payload: MyCreaturesType[] }
        | { type: 'UPDATE'; payload: MyCreaturesType[] }
        | { type: 'ADD_FROM_CLOUD'; payload: MyCreaturesType[] }
        | { type: 'UPDATE_FROM_CLOUD'; payload: MyCreaturesType[] }
        | { type: 'DELETE'; payload: MyCreaturesType[] }
        | { type: 'MAKE_LAUGH'; payload: {id: string} }
        | { type: 'FEED'; payload: {id: string, level:number} }
        | { type: 'EMPTY' }


    const reducer= (state:any, action:Action) => {
        switch (action.type) {
            case 'ADD':
                return [
                    ...state, 
                    ...action.payload.map((item: MyCreaturesType) => ({
                        ...item,
                        isSynchronized: false,
                    }))
                ]; 
            case 'UPDATE':
                return state.map((item: any) => {
                        const updatedItem = action.payload.find((itemAction:MyCreaturesType) => itemAction.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: false };
                        }
                        return item;
                    });
            case 'ADD_FROM_CLOUD':
                return [
                    ...state, 
                    ...action.payload.map((item: MyCreaturesType) => ({
                        ...item,
                        isSynchronized: true,
                    }))
                ]; 
            case 'UPDATE_FROM_CLOUD':
                return state.map((item: MyCreaturesType) => {
                        const updatedItem = action.payload.find((itemAction:MyCreaturesType) => itemAction.id === item.id)  
                        if (updatedItem) {
                            return { ...item, ...updatedItem, isSynchronized: true };
                        }
                        return item;
                    });
            case 'MAKE_LAUGH':
                return state.map((item: MyCreaturesType) => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            lastLaugh: Date.now(), 
                            isSynchronized: false 
                        };
                    }
                    return item;
                    });
            case 'FEED':
                return state.map((item: MyCreaturesType) => {
                    if (item.id === action.payload.id) {
                        return {
                            ...item,
                            lastFeed: item.lastFeed + (action.payload.level * 1000 * 60 * 60 * 24),
                            lastLaugh: Date.now(),
                            isSynchronized: false
                        };
                    }
                    return item;
                    });
            case 'DELETE':
                return state.filter((item: MyCreaturesType) => !action.payload.some((creature:MyCreaturesType) => creature.id === item.id)) ;

            case 'EMPTY':
                return [];

            default:
                return state;
        }
     }
    const [state, dispatch] = useReducer(reducer, initialState);


    const stateRef = useRef(state);

    useEffect(() => {
        stateRef.current = state;
        if ( process.env.NODE_ENV==="development" ) {
            console.log("my creatures states changed", state)
        }
    }, [state]);

    const add=(datas: MyCreaturesType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'ADD', 
            payload: datas})
    }

    const update=(datas: MyCreaturesType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'UPDATE', 
            payload: datas})
    }

    const remove=(datas: MyCreaturesType[])=>{
        if ( !datas || !datas.length) { return }
        dispatch({
            type: 'DELETE', 
            payload: datas})
    }

    const emptyState=()=>{
        dispatch({
            type: 'EMPTY'})
    }

    const makeLaugh=(creatureId: string)=>{
        if ( !creatureId ) { return }
        dispatch({
            type: 'MAKE_LAUGH', 
            payload: {id: creatureId}})
    }

    const feed=(creatureId: string, level:number)=>{
        if ( !creatureId || !level ) { return }
        dispatch({
            type: 'FEED', 
            payload: {id: creatureId, level}})
    }

    useEffect(()=>{
        if(user && !isAuthLoading && cloudStorage){
            const unsubscribe = cloudStorage?.subscribeToMyCreatures(
                (creatures, creatureToRemove)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("unsubscribeMyCreatures", creatures)
                    }
                    if ( creatures && creatures.length>0 ) {
                        const itemsToAdd = creatures?.filter(
                            (item:MyCreaturesType) => !stateRef.current.some((stateItem: MyCreaturesType) => stateItem.id === item.id)
                        ) || [];
                        if ( itemsToAdd && itemsToAdd.length > 0 ){
                            dispatch({
                                type: 'ADD_FROM_CLOUD', 
                                payload: itemsToAdd})
                        }

                        const updatedItemsState = creatures
                        .filter(
                            (item:MyCreaturesType) => stateRef.current.some((stateItem: MyCreaturesType) => stateItem.id === item.id)
                        ) || []
                
                        if ( updatedItemsState && updatedItemsState.length > 0 ){
                            dispatch({
                                type: 'UPDATE_FROM_CLOUD', 
                                payload: updatedItemsState})
                        }
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("add and update for creatures", itemsToAdd, updatedItemsState)
                        }
                        
                    }

                    if ( creatureToRemove && creatureToRemove.length > 0 ){
                        dispatch({
                            type: 'DELETE', 
                            payload: creatureToRemove})
                    }
                },
                setIsLoading
            )

            return () => {
                if ( unsubscribe ) { unsubscribe() }
            }
        }
    },[user, isAuthLoading, cloudStorage])


    useEffect(()=>{
        if ( cloudStorage && user && state && state.length > 0 ){
            setIsLoading(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("setCreaturesToCloud preSave")
            }
            const unsynchronizedItems = state.filter((item:MyCreaturesType)=>!item.isSynchronized)
            if ( unsynchronizedItems && unsynchronizedItems.length > 0 ){
                cloudStorage?.setCreaturesToCloud(unsynchronizedItems).then((result)=>{
                    if ( process.env.NODE_ENV==="development" ) {
                        console.log("setCreaturesToCloud", result)
                    }
                })
            }
        }
    }, [cloudStorage, user, state])

    useEffect(()=>{
        if ( !user && !isAuthLoading) {
            emptyState()
            setIsLoading(false)
        }
    },[user, isAuthLoading])

    useEffect(()=>{
        if (isSigning) {
            if ( wasUserNotConnected){                
                dataFromNotConnecterRef.current = state                
            }      
            emptyState()
            setIsLoading(true)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wasUserNotConnected", state)
            }
        }
    },[isSigning, wasUserNotConnected])

    useEffect(()=>{
        if ( !isLoading && user && isNewUser && !state.length && dataFromNotConnecterRef.current && dataFromNotConnecterRef.current.length > 0 ){
            add([...dataFromNotConnecterRef.current.map((item:MyCreaturesType) => ({
                ...item,
                ownerId: user?.uid,
                isSynchronized: false
            }))])
            
            if ( process.env.NODE_ENV==="development" ) {
                console.log("add data from wasUserNotConnected", dataFromNotConnecterRef.current, state,stateRef.current)
            }
            dataFromNotConnecterRef.current = null
        }
    },[isLoading, user ])

    useEffect(()=>{
        if ( logOut ){
            emptyState()
        }
    },[logOut])

    return {
        state,
        isLoading,
        add,
        makeLaugh,
        feed
    }
}