import { useRouter } from "next/router";
import { createContext, useContext, useEffect, useState } from "react";
import { PaymentContextType } from './type/paymentContextType';
import { PaymentType } from "./type/paymentType";
import { AuthenticationContext } from "../authentication/authenticationContext";
import stripeToPaidHelper from "../../helpers/stripeToPaidHelper";
import { FirebaseContext } from "../firebase/firebaseContext";
import ShowPaidContentModal from "./layout/modal/showPaidContentModal";
import { useSearchParams } from "next/navigation";
import { buildApiStripeValidateuUrl } from "@/app/helpers/buildUrlHelper";
import { PaymentModalType } from "./type/paymentModalType";
import { buildPaymentModalTypeFromType } from "@/app/helpers/buildPaymentModalTypeFromType";
import { ContentPriceList } from "@/app/const/contentPriceList";
import { v4 } from "uuid";
import { GlobalDataUserContext } from "../global/globalDataUserContext";
import { GlobalDataContext } from "../global/globalDataContext";
import { getCreatureDetailsHelper } from "@/app/helpers/getCreatureDetailsHelper";
import { ContentPriceType } from "./type/contentPriceType";

export const PaymentContext = createContext({} as PaymentContextType)

export const PaymentProvider= (props:any) => {

    const router = useRouter();
    const { language, session_id, error, priceId, itemId } = router.query;
    const searchParams = useSearchParams()
    const [isLoadingSession, setIsLoadingSession] = useState(false)
    const [validPayment, setValidPayment] = useState(false)
    const [errorPayment, setErrorPayment] = useState(false)
    const [showPaidContentModal, setShowPaidContentModal] = useState<PaymentModalType|false>(false)
    const [allPayment, setAllPayment] = useState<PaymentType[]>([])
    const [isPaymentLoading, setIsPaymentLoading] = useState(true)
    const {
        cloudStorage,
        user,
        isAuthLoading,
        showDeleteAccountModal
    } = useContext(AuthenticationContext)

    const {
        logAnalyticsEvent,
        isFirebaseLoading
    } = useContext(FirebaseContext)

    const {
        location
    } = useContext(GlobalDataContext)

    const {
        addFood,
        addNewCreature,
        isGlobalDataUserLoading,
        addNewEgg
    } = useContext(GlobalDataUserContext)

    /*
    const {
        getEpisodeDetailBySeasonIdAndEpisodeId,        
        isGlobalLoading,
        prices
    } = useContext(GlobalDataContext)
     */

    useEffect(()=>{
        if ( allPayment.length && isPaymentLoading){
            setIsPaymentLoading(false)
        }
    },[allPayment,isPaymentLoading])

    useEffect(()=>{
        if (!user) {
            setIsPaymentLoading(false)
        }
    },[user])

    const addPayment=async(purchase:PaymentType)=>{
        try {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("wtf addPayment",cloudStorage,purchase)
            }
                await cloudStorage?.addPaymentToCloud(purchase)
                logAnalyticsEvent("purchase",{itemId:purchase.itemId, transaction_id:purchase.providerPaymentId, value: purchase.price, currency: "EUR"})              
                setAllPayment(prevState => ([
                    ...prevState,
                    ...stripeToPaidHelper([purchase])
                ]))
        } catch (error: any) {
            if ( process.env.NODE_ENV==="development" ) {
                console.log("analytics purchase error", error)
            }
        }
    }

    const onClosePaymentLoginModal = () => {
        setShowPaidContentModal(false)        
        setErrorPayment(false)
        setValidPayment(false)
    }

 

    useEffect(()=>{
        if ( user && cloudStorage && !isFirebaseLoading){
            if ( process.env.NODE_ENV==="development" ) {console.log("WTH SUBSCRIBE PAID CONTENT")   }
            setAllPayment([])
            const unsubscribePayment= cloudStorage?.subscribeToPayment(
                (paidContent)=>{
                    setAllPayment((paidContentState)=>{
                        if ( process.env.NODE_ENV==="development" ) {console.log("WTH SNAPSHOT paidcontent SUBSCRIBE", paidContent)   }
                        const paidContentToAdd=[]as PaymentType[]
                        paidContent.map((item)=>{
                            if ( !paidContentState.find((paidContentItem)=>paidContentItem.providerPaymentId == item.providerPaymentId)){
                                paidContentToAdd.push(item)
                            }
                        })
                        if ( process.env.NODE_ENV==="development" ) {
                            console.log("WTF paidcontent",paidContentToAdd, [
                                ...paidContentState,
                                ...paidContentToAdd
                            ])
                        }
                        return [
                            ...paidContentState,
                            ...paidContentToAdd
                        ]                    
                    })
                    },
                    setIsPaymentLoading
                )

            return () => {
                if ( unsubscribePayment) unsubscribePayment()
            }
        }
    },[user,cloudStorage, isFirebaseLoading])

    useEffect(()=>{
        if( !user){
            setAllPayment([])
        }
    },[user])
    

    const addItemForPayment=async(item:ContentPriceType, paymentId:string="")=>{ 
        const uuid= v4()
        if ( item ){
            if ( item.providerId.stripe ) {
                await addPayment(
                    {
                        id: uuid,
                        itemId: item?.id as string,
                        timestamp: new Date().getTime(),
                        price: item?.price ,
                        consumed: true,
                        providerName: "stripe",
                        providerPaymentId: paymentId //stripeSession.result.payment_intent
                    }
                )
            }
            switch(item?.id){
                case "food100":
                    addFood(100)
                    break;
                case "food500":
                    addFood(500)
                    break;
                case "food1000":
                    addFood(1000)
                    break;
                case "eggNormal":
                    addNewEgg("NORMAL", true)
                    break;
                case "eggLegendary":
                    addNewEgg("LEGENDARY", true)
                    break;
                case "eggAquatic":
                    addNewEgg("AQUATIC", true)
                    break;
                case "grimlak":
                    const creature = getCreatureDetailsHelper("edc2d073-e126-4ba2-a15e-24daac9aff38")
                    creature && addNewCreature(creature, undefined, "LEGENDARY")
                    break;
                case "gromol":                                
                    const creature2 = getCreatureDetailsHelper("d01cca04-4430-479c-9ada-c74afb71b4ce")
                    creature2 && addNewCreature(creature2, undefined, "LEGENDARY")
                    break
                case "dimzer":                                
                    const creature3 = getCreatureDetailsHelper("09e47728-2657-41d0-9f90-83d71707d33e")
                    creature3 && addNewCreature(creature3, undefined, "LEGENDARY")
                    break;
                case "rostok":                                
                    const creature4 = getCreatureDetailsHelper("88de64ab-34f5-4eff-8d6a-2ed481316abc")
                    creature4 && addNewCreature(creature4, undefined, "LEGENDARY")
                    break;
                case "splork":                                
                    const creature5 = getCreatureDetailsHelper("a48edb20-7fa2-4c91-b53c-4a283eaa3992")
                    creature5 && addNewCreature(creature5, undefined, "LEGENDARY")
                    break;
                case "slobzer":                                
                    const creature6 = getCreatureDetailsHelper("169ac202-f7a7-4fb2-aedc-d3003c1e25f7")
                    creature6 && addNewCreature(creature6, undefined, "LEGENDARY")
                    break;
                case "bozor":                                
                    const creature7 = getCreatureDetailsHelper("49be1a73-48b0-467f-b363-d1361d61bcc5")
                    creature7 && addNewCreature(creature7, undefined, "LEGENDARY")
                    break;
                case "crookzy":                                
                    const creature8 = getCreatureDetailsHelper("b5c9f361-7abb-4281-8884-9ff4a23ef1c4")
                    creature8 && addNewCreature(creature8, undefined, "LEGENDARY")
                    break;
                case "gary":                                
                    const creature9 = getCreatureDetailsHelper("c8dfb1f9-9b48-4c6b-952b-89b71838e69f")
                    creature9 && addNewCreature(creature9, undefined, "LEGENDARY")
                    break;
                case "gilbert":                                
                    const creature10 = getCreatureDetailsHelper("d01cca04-4430-479c-9ada-c74afb71b4ce")
                    creature10 && addNewCreature(creature10, undefined, "LEGENDARY")
                    break;                        
                case "zone1":
                    const zoneId= v4()
                    await cloudStorage?.addZoneToCloud({
                        id: zoneId,
                        ownerId: user?.uid as string,
                        shared:"private",
                        name: Date.now().toString(),
                        city: "",
                        country: "",
                        lat: location?.lat as number,
                        lng: location?.lng as number,
                        date : new Date(),
                        coords: []
                    })
                    break;
                default:
                    break;
            }
        }
    }

    const checkSession = async()=> {
        
        const item = ContentPriceList.find((content)=>content.id == itemId)

        if ( process.env.NODE_ENV==="development" ) {
            console.log("wtf session fetchsession_id",session_id, item)
        }

        const options = {
            method: "POST",
            headers: {
                "Content-type": "application/json",        
                'Authorization' : "Bearer " + await user?.getIdToken()
            }
        }

        const stripeSession = await fetch(process.env.NEXT_PUBLIC_API_URL_V2 + buildApiStripeValidateuUrl(language as string) + '?sessionId=' + session_id, options).then((res) =>
            res.json()
        )

        // TODO:  && stripeSession.result.customer_email == user?.email ( check mail later)
        if ( stripeSession && stripeSession.result && stripeSession.result.payment_intent  ) {
            //if ( !getContentPaidType(episodeDetail?.uuid as string)) {
                if ( process.env.NODE_ENV==="development" ) {
                    console.log("wtf episodePaid", allPayment, isAuthLoading)
                }
                
                addItemForPayment(item as ContentPriceType, stripeSession.result.payment_intent)
                    
            //}
            setValidPayment(true)
            setErrorPayment(false)
            if ( process.env.NODE_ENV==="development" ) {
                console.log("session stripe", stripeSession)
            }
            setIsLoadingSession(false)
            
            
        }else{
            setErrorPayment(true)
            setValidPayment(false)
            setIsLoadingSession(false)
        }
        
        // TODO: new page to play redirect to remove params from session
        // await router.push(buildGameUrl(language as string,parseInt(seasonId as string),parseInt(episodeId as string),0))
        const urlObj = new URL(window.location.href, window.location.origin);
        if (urlObj.search) {
            // Rediriger vers l'URL sans les paramètres de requête
            const baseUrl = `${urlObj.origin}${urlObj.pathname}`;
            router.replace(baseUrl);
        }
        setShowPaidContentModal(buildPaymentModalTypeFromType(itemId as string))

    }


    useEffect(()=>{
        if ( process.env.NODE_ENV==="development" ) {
            console.log("wtf usesearchparam", user, session_id, cloudStorage, isAuthLoading)
        }
        //const session = searchParams.get('session_id')
        //const error = searchParams.get('error')
        if ( !isAuthLoading && session_id && !isGlobalDataUserLoading && user && cloudStorage){
            checkSession().then()
        }
        if ( error && user && itemId ){
            setErrorPayment(true)
            setShowPaidContentModal(buildPaymentModalTypeFromType(itemId as string))
            const urlObj = new URL(window.location.href, window.location.origin);
            if (urlObj.search) {
                // Rediriger vers l'URL sans les paramètres de requête
                const baseUrl = `${urlObj.origin}${urlObj.pathname}`;
                router.replace(baseUrl);
            }
        }
    },[session_id,error, itemId, isAuthLoading, isGlobalDataUserLoading, user, cloudStorage])





    return (
        <PaymentContext.Provider value={{
            isPaymentLoading,
            allPayment,
            setShowPaidContentModal,
            showPaidContentModal,
            validPayment,
            errorPayment,
            setErrorPayment,
            setValidPayment,
            addPayment,
            addItemForPayment
        }}>
            {props.children}
            <ShowPaidContentModal/>
        </PaymentContext.Provider>
    )
}
